import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import ines from '../Home/images/ines.jpg'

const Ines = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={ines} alt="Ženske priče - Ines - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Ines</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Ines je bila moje sve. Kad bih morala da je predstavim kao knjigu, bila bi moj dnevnik prepun tajni i kuvar prepun egzotičnih začina. Kada bih je morala opisati kao pjesmu, bila bi istovremeno romantična sonata i dramatični ep o zmaju i vitezu. Kada bi Ines bila instrument, čuli biste violinu i bubanj u pratnji laganog zvižduka. Takva vam je Ines – uho za sve nedaće, kormilo svakom danu, podrška i predvodnik, razigranost i tišina. Ona je prijatelj kakvog svi priželjkujemo ali ga rijetko stičemo; ili onaj kojeg imamo ali ne umijemo da ga zadržimo.
                            </p>
                            <p className="text-single-news">
                                Čudna je to stvar – prijateljstvo. Često mi se čini da u tom odnosu izraste nešto veće od oboje; kao biljka koja se korijenjem duboko sveže za zemlju, zatim granama obuzme ogradu kraj koje raste, a onda se vine u visinu tako da ne možete da je navršite čak i kad se propnete na vrhove prstiju sopstvene mašte. Ta bujica života ostane vam van domašaja, a najbolja stvar koju možete učiniti jeste da je zalivate i štitite od velikog vjetra, a neizostavno da joj se svakodnevno divite jer tako najljepše cvjeta.
                            </p>
                            <p className="text-single-news">
                                Moja Ines se nije štedjela, a ja sam je uvijek ostajala željna iznova i iznova. I nakon beskrajnih šetnji niz kej, u kući bih je odmah okrenula telefonom i naša priča bi nastavila baš kao da tek počinje. Ima takvih ljudi – njihove riječi ispijate kao žedni izvorsku vodu, pospani jutarnju kafu, a njihovo prisustvo zapljuskuje vas kao oblak najfinijeg parfema. To je dar koji zaslužite ne znajući kako. Kroz sve ove godine nisam uspjela da odgonetnem koji tajni sastojak je naše prijateljstvo učinilo tako dugotrajnim i uvijek svježim. Ipak, postala sam sigurna u jedno, što mi je zauvijek otvorilo srce cijelom svijetu. Dobrota je vrlina koja ne traži razloge, a davanje sebe samo rijetki poznaju kao pronalazak bogatstva – u drugima.
                            </p>                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Ines;