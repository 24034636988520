import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import galina from '../Home/images/galina.jpg'

const Galina = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={galina} alt="Ženske priče - Galina - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Galina</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                „Hvala, i dođi da se igramo!“, tiho bi rekla čuleći na uvijek istom mjestu kraj bunara dok bi gurala suvi kolačić s grožđicama u malena usta, a ja bih već uveliko hitala ka drugoj strani kapije. Galina je bila najmlađe dijete najsiromašnije porodice u našoj ulici, a o toj činjenici u našoj kući nikad se nije govorilo naglas. I slučajno ili baš ne, njihova šljiva nadvijala se nad našu ogradu tako da su s jeseni svi plodovi padali na našu stranu. Njihovi psi najčešće su lunjali u potrazi za hranom po tuđim pragovima. Dosta puta bi im se krava na ispaši otkinula sa lanca i prejela se kukuruza u komšijskoj njivi. Jedna je tako uginula od nadutosti. Te slike su me još kao dijete proganjale – činilo se da i sama priroda bježi od te nesrećne porodice.
                            </p>
                            <p className="text-single-news">
                                Bila sam premlada kako bih shvatila prave razloge njihovog siromaštva, ali činilo se da bolna oskudica na njihove duše nije ostavila baš nikakvog traga. Galina, uvijek gladna kolača i voća, sa sjajnim zjenicama i uvijek toplim šakama, blagodarila je na našim sitnim darovima možda čak više nego što su zaista zasluživali. Kad god bi majka pekla štrudlu, tri vruća parčeta umotana u krpu odmah su pod mojom miškom izlijetala ka njihovoj kući. Isto je bilo sa prezrelim voćem, lončićima mlijeka, zrncima kafe, pa čak i mojim preraslim haljinicama, izanđalim cipelama, starim trakama za kosu. Nismo imali mnogo, ali je uvijek bilo dovoljno i za Galinu.
                            </p>
                            <p className="text-single-news">
                                Isprva nisam razumijevala zašto smo toliko uporno njegovali milostinju prema porodici koju nismo ni znali, a još manje zašto mi je Galina svakog puta ne samo zahvaljivala već me i zvala da se igramo. „Drugi put, Galina“, često bih odmahivala rukom, već nestajući na kapiji. Majka nije znala za te pozive, a ja sama ih nisam razumjela. „Dosta je što im donosim sve te stvari“, govorila bih sebi i jurila za drugom djecom niz ulicu. Tako je Galina ostala vječni neznanac.
                            </p>
                            <p className="text-single-news">
                                Jednog dana se odselila, a da to nisam ni saznala sve dok nisam primijetila da je zavežljaj hurmašica cio dan proveo usamljen na Galininom mjestu kraj bunara. „Više nema tvoje drugarice“, rekla mi je sjetno mama. Odjednom, osjetila sam veliku tugu. Ne znam šta me je više zaboljelo – ta riječ, „drugarica“, koju nisam zaslužila, ili poziv kojem se nikad nisam odazvala. Prvi put, pomislila sam koliko je Galina zapravo bila usamljena. Sjetila sam se njenih obraščića rumenih kao mamine buhtlice i slavujkastog glasića kako ponavlja: „Hvala, dođi sutra da se igramo“.
                            </p>
                            <p className="text-single-news">
                                Galina, sad kada bih mogla da te vratim u to dvorište, igrala bih se s tobom dok se mjesec ne bi pretvorio u palačinku kojom sam nesvjesno kupila tvoje drugarstvo, a zvijezde nas uspavale na utabanoj stazi pokraj bunara.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Galina;