import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import isidora from '../Home/images/isidora.jpg'

const Isidora = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={isidora} alt="Ženske priče - Isidora - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Isidora</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Posljednji put kada sam plakala, bilo je pri čitanju rođendanske čestitke moje prijateljice iz srednje škole. Priznaću vam neobičnu stvar o sebi – nikad ne pročitam čestitke za rođendane redom i u cijelosti. Uvijek preskočim koju riječ, ako stvari postanu intenzivne – i cio red; neke čitam od sredine ka vrhu, a u nekima konjićevim skokom skliznem što brže na kraj, tamo gdje me obično dočeka „tvoja…“ ili „tvoji…“, ta prosta prisvojna zamjenica koja mi uzdrhti najtanju strunu u rijetko posjećenoj zabiti svjesnog. Novogodišnje čestitke čitam redom i strpljivo; nalazim da su beskrajno šarmantne u svom neumornom, ponavljajućem i predvidivom optimizmu, uvijenom u splet izraza – predstavnika lijepih društvenih normi. Nije slučajno postala stvar poslovnog bontona. Uostalom, nova godina svima nastupa u isti čas, u njoj nema ničeg privatnog.
                            </p>
                            <p className="text-single-news">
                                Ali rođendani, to su intimne priče svačijeg postojanja, u kojima učestvuju samo oni koji su imali čast za određeno postojanje da saznaju. U rođendanima istrajavaju samo oni najuporniji. Njima praznik nije posljednji dan posljednjeg mjeseca. To je neki nasumični, u kalendaru skriveni dan, koji se otkriva samo brižnim duhom i željom da svijet makar na deset sekundi (koliko u prosjeku traje čitanje prosječne rođendanske čestitke) postane malo bolje mjesto. To je skrivena kafanica sa tri stola u zaboravljenom haustoru koja nimalo slučajno služi najukusniju kafu u gradu. Uspjeh čestitanja je neizostavan, jer draž te privatne emotivne žurke koju čestitanje priređuje nikad ne može biti iscrpljena. Zašto, konačno, ne čitam svoje čestitke? Pa, jednostavno, jer je za sudar sa lijepom, boljom, uspješnijom, odlučnijom i srećom okupanom sobom, dostojnom rođendanske čestitke, potrebna hrabrost. Negdje sam pročitala da je <i>hrabrost mjerilo našeg srčanog učešća u životu</i>.
                            </p>
                            <p className="text-single-news">
                                Hrabrosti imam, ali je srce pretekne. Njegovi damari odzvanjanju tako jako i zaglušuju sve ostalo, kao stope u praznom haustoru koje i dalje mirišu na najdražu kafu…
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Isidora;