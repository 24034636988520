import React from 'react';

// style
import './style.css'

// components
import { Container, Row, Col } from 'react-bootstrap'

// images
import capsule from './images/intro/omcafe-espresso-capsule.png'
import capsuleSingle from './images/intro/omcafe-capsule.png'
import shadow from '../../assets/images/intro/shadow.jpg'

// products
import aparat from './images/products/Omcafe Espresso Capsule Aparat.png'
import strong from './images/products/Omcafe Capsule Strong.png'
import corposo from './images/products/Omcafe Capsule Corposo.png'
import forte from './images/products/Omcafe Capsule Forte.png'
import decaffeinato from './images/products/Omcafe Capsule Decaffeinato.png'
import venezia from './images/products/Omcafe Capsule Venezia.png'
import firenze from './images/products/Omacafe Capsule Firenze.png'

// intensity
import i10 from './images/intensity/i10.svg'
import i9 from './images/intensity/i9.svg'
import i8 from './images/intensity/i8.svg'
import i7 from './images/intensity/i7.svg'
import i6 from './images/intensity/i6.svg'
import i5 from './images/intensity/i5.svg'

const CapsuleEn = () => {
    return (
        <>
            <div>
                <img className="intro-image-capsule" src={capsule} alt="omcafe" />
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} className="text-center">
                        <p className="text-intro-capsule">
                            OMCAFÉ Espresso Capsules offer an ultimately intimate hedonistic experience of espresso savoring. Elegant and simple way to consume your favorite coffee aroma in the privacy of your home or comfort of an office space. Each capsule is made of perfect ratio of high-quality ingredients, which guarantees equally seductive taste with each sip. Thanks to this, each next OMCAFÉ Espresso Capsule drinking is a pleasure identical to the first one, which is why you always return to it.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <img className="intro-image-single-capsule" src={capsuleSingle} alt="omcafe" />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <img className="shadow-image-shadow-capsule" src={shadow} alt="omcafe" />
                    </Col>
                </Row>

            </Container>


            <div className="aparat">
                <Container>
                    <Row>
                        <Col lg={6} className="col-align-left-aparat-capsule">
                            <img className="image-aparat-capsule" src={aparat} alt="omcafe" />
                        </Col>
                        <Col lg={6} className="col-align-aparat-capsule">
                            <div className="align-content-aparat-capsule">
                                <h2 className="title-aparat-capsule">OMCAFÉ ESPRESSO CAPSULE MACHINE</h2>
                                <p className="text-aparat-capsule">
                                    OMCAFÉ Espresso Capsule machine is compatible with Nespresso capsules, and processing of fine ground beans of premium coffee within seconds bringing warmth to your home, that any coffee lover dreams about. It fits in easily in any interior and temperature of the beverage it produces is perfect for instant consumption, giving you pleasure without delay.
                                </p>
                                <button className="button-aparat-capsule">ADD TO SHOPPING CART</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row>
                    <Col lg={12}>
                        {/* card 1 */}
                        <div className="card-capsule">
                            <Row className="column-reverse-card-capsule">
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-left-card-capsule">
                                        <h2 className="title-card-capsule">Strong</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Strong stands out for its strength, and its completely intensive aroma pleases coffee lovers with a lot of character.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i10} alt="omcafe" />
                                        <button className="button-card-capsule btn-strong">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={strong} alt="omcafe" />
                                </Col>
                            </Row>
                        </div>
                        {/* card 2 */}
                        <div className="card-capsule last-card-capsule">
                            <Row>
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={corposo} alt="omcafe" />
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-right-card-capsule">
                                        <h2 className="title-card-capsule">Corposo</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Corposo is a perfect ratio of strong caffeine experience of sumptuous taste.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i9} alt="omcafe" />
                                        <button className="button-card-capsule btn-corposo">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>





                        {/* card 4 */}
                        <div className="card-capsule">
                            <Row className="column-reverse-card-capsule">
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-left-card-capsule">
                                        <h2 className="title-card-capsule">Forte</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Forte belongs to the range of stronger and fuller coffee aromas, which makes it ideal in moments of desire for additional energy.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i8} alt="omcafe" />
                                        <button className="button-card-capsule btn-forte">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={forte} alt="omcafe" />
                                </Col>
                            </Row>
                        </div>
                        {/* card 5 */}
                        <div className="card-capsule last-card-capsule">
                            <Row>
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={decaffeinato} alt="omcafe" />
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-right-card-capsule">
                                        <h2 className="title-card-capsule">Decaffeinato</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Decaffeinato is ideal for coffee lovers who do not want to give up the taste of their favorite beverage but who have the need to reduce the caffeine consumption. During solvent rinsing, about 97% of caffeine content is removed. Paying a special attention during roasting of beans treated in such away, we managed to save the aroma as well as other coffee properties.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i7} alt="omcafe" />
                                        <button className="button-card-capsule btn-decaffeinato">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {/* card 6 */}
                        <div className="card-capsule">
                            <Row className="column-reverse-card-capsule">
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-left-card-capsule">
                                        <h2 className="title-card-capsule">Venezia</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Venezia gains sympathies of lovers of moderate coffee notes who first and foremost appreciate the full quality and its smooth seduction of taste.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i6} alt="omcafe" />
                                        <button className="button-card-capsule btn-venezia">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={venezia} alt="omcafe" />
                                </Col>
                            </Row>
                        </div>
                        {/* card 7 */}
                        <div className="card-capsule last-card-capsule">
                            <Row>
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={firenze} alt="omcafe" />
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-right-card-capsule">
                                        <h2 className="title-card-capsule">Firenze</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Firenze is the most gentle beverage in the family of espresso capsules, and wears this name with pride because of its mild intensity and relaxing coffee aroma.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i5} alt="omcafe" />
                                        <button className="button-card-capsule btn-firenze">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default CapsuleEn;