import * as React from "react"

function SvgComponent(props) {
  return (
    <svg fill="#fff" viewBox="0 0 257.371 257.371" {...props}>
      <path d="M0 241.335L241.332.002l16.035 16.035L16.034 257.37z" />
      <path d="M0 16.036L16.033 0l241.334 241.333-16.035 16.035z" />
    </svg>
  )
}

export default SvgComponent
