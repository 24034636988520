import React from 'react'

// style
import './style.css';

// components
import { Container, Row, Col } from 'react-bootstrap';

import Coffee from '../../assets/images/intro/Coffee'
import shadow from '../../assets/images/intro/shadow.jpg'
import instant from './images/instant-omcafe.jpg'

// products
import omcafe2in1 from './images/products/Omcafe 2 in 1.png'
import omcafe2in1soya from './images/products/Omcafe 2 in 1 soya.png'
import omcafe3in1soyaVannila from './images/products/Omcafe 3 in 1 soya vanilla.png'
import omcafe3in1soya from './images/products/Omcafe 3 in 1 soya.png'
import omcafe3in1 from './images/products/Omcafe 3 in 1.png'
import omcafe3in1smedjiSecer from './images/products/Omcafe 3in1 smedji secer.png'
import omcafeCapucino from './images/products/Omcafe capucino.png'

const InstantEn = () => {
    return (
        <>
            <div>
                <img className="intro-image-instant" src={instant} alt="Omcafé" />
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} className="text-center">
                        <p className="text-intro-instant">
                            Festina lente or hasten slowly – that would be our warm recommendation called OMCAFÉ instant. When your daily schedule is tight, and taking a break becomes a necessity, a spoonful of this coffee will make time start working for you.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Coffee className="beans-intro-image-instant" />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <img className="shadow-intro-image-instant" src={shadow} alt="Omcafé" />
                    </Col>
                </Row>


                <Row>
                    <Col lg={12}>


                        <div className="card-instant bg-2in1-red">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">Omcafé 2 in 1</h2>
                                        <p className="text-card-instant">A cup of real coffee rights many wrongs, OMCAFÉ 2 in 1 in translation means: good mood and a new dose of energy in a bag.</p>
                                        <button className="button-card-instant">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe2in1} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>


                        <div className="card-instant bg-3in1-gold">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">OMCAFÉ 3 IN 1 BROWN SUGAR</h2>
                                        <p className="text-card-instant">When you wish to add a pinch of refreshment to your favorite beverage, there is OMCAFÉ 3 in 1 with brown sugar, which gives coffee a more intensive, caramelized taste and darker color. Small changes make up for great pleasures sometimes.</p>
                                        <button className="button-card-instant">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe3in1smedjiSecer} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>


                        <div className="card-instant bg-3in1-gray">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">Omcafé 3 In 1</h2>
                                        <p className="text-card-instant">Classic is timeless. That is why a perfect blend of coffee, milk and sugar does not need new words, only pleasure with each repetition.</p>
                                        <button className="button-card-instant">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe3in1} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>

                        <div className="card-instant bg-capucino">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">Cappuccino</h2>
                                        <p className="text-card-instant">A love letter from Italy to the rest of the world is called cappuccino. In only a few seconds of preparation, OMCAFÉ Cappuccino opens pages of this gentle, foamy caffeine story right in front of you. Silky aroma of coffee intertwined with milk, covered in perfect airy foam, contents of your cup transform into multi-layered gift for your palate.</p>
                                        <button className="button-card-instant">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafeCapucino} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>

                        <div className="card-instant bg-soya-2in1-blue">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">3 In 1 Soya Natural</h2>
                                        <p className="text-card-instant">Drinking coffee should always be within the reach. With this sweet bean, nothing shall be in the way of your daily pleasures.</p>
                                        <button className="button-card-instant">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe3in1soya} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>


                        <div className="card-instant bg-soya-3in1-vanilla-yellow">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">3 In 1 Soya Vanilla </h2>
                                        <p className="text-card-instant">The aromatic herbal trio brings a special combination of tastes: playful vanilla aroma, combined with mild notes of soy milk, on the basis made of sweetened beans of fine grind coffee. </p>
                                        <button className="button-card-instant">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe3in1soyaVannila} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>

                        <div className="card-instant  bg-soya-3in1-green last-card-instant">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">2 In 1 Soya Natural</h2>
                                        <p className="text-card-instant">If you are on a special diet or you simply prefer herbal milk to cow, we welcome you to the world of OMCAFÉ instant coffee with soy milk.</p>
                                        <button className="button-card-instant">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe2in1soya} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default InstantEn;