import React from 'react'

// style
import './style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import tarik from '../Home/images/Omcafe Espresso Capsule - Tarik Filipovic.jpg'

const TarikFilipovicNoviBrendAmbasador = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={tarik} alt="Omcafe Espresso Capsule Tarik Filipović" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">News</h4>
                            <h2 className="title-single-news">Tarik Filipović je novi ambasador brenda</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Glumac i televizijski voditelj Tarik Filipović u narednom periodu biće zaštitno lice OMCAFÉ Espresso
                                Capsula.
                            </p>
                            <p className="text-single-news">
                                Počevši od 19. oktobra, Tarik Filipović će biti predstavljen u novoj OMCAFÉ Espresso Capsule
                                marketinškoj kampanji koja nas poziva da „izaberemo svoj užitak“, putem televizijskog, štampanog i
                                digitalnog oglašavanja.
                            </p>
                            <p className="text-single-news">
                                Kao brend ambasador OMCAFÉ Espresso Capsula, Tarik će sa ljubiteljima OMCAFÉ proizvoda podijeliti nove jedinstvene OMCAFÉ Espresso Capsule priče inovativnog dizajna i kvalitetne recepture.
                            </p>
                            <p className="text-single-news">
                                „Kao veliki ljubitelj espresso kafe s posebnim zadovoljstvom govorim o saradnji sa Bom Impeks kompanijom“, rekao je Tarik Filipović i dodao kako je od samog početka zajednička ideja bila da se udruženim snagama promoviše sve ono što je karakteristično i specifično za espresso kapsule, a to je jednostavan i elegantan pristup ispijanja kafe.
                            </p>
                            <p className="text-single-news">
                                Bom Impeks kompanija, tvorac brenda OMCAFÉ, ističe da su izuzetno zadovoljni što su angažovali harizmatičnog Tarika Filipovića kao brend ambasadora, te da ubzo spremaju nove projekte i humanitarne aktivnosti na području Bosne i Hercegovine.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TarikFilipovicNoviBrendAmbasador;