import React from 'react'

// components
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";


// style
import '../Home/style.css';
import './style.css';

// images
import OrganicCoffee from "../Home/images/Omcafe-organic-coffee.jpg";
import aparat from '../Home/images/Omcafe espresso capsule aparat.jpg'
import prodavnica from '../Home/images/Omcafe online prodavnica.jpg'
import tarik from '../Home/images/Omcafe Espresso Capsule - Tarik Filipovic.jpg'
import zenskePricePaket from '../Home/images/poklon-paket-omcafe-zenske-price.jpg'
import kafaKojaDise from '../Home/images/kafa-koja-dise.jpg'

const AllNewsEn = () => {
    return (
        <Container fluid>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={kafaKojaDise} alt="Omcafe - Kafa koja dise" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">News</h4>
                        <h2 className="title-card-home">Kafa koja „diše“</h2>
                        <p className="text-card-home">
                            Ako ste veliki ljubitelj kafe, sigurno ste primjetili da se na vrećicama za kafu nalazi jednosmjerni  ventil za degasifikaciju koji omogućava zrnima kafe da „dišu“.
                        </p>
                        <Link to="/kafa-koja-dise" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Read more
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">News</h4>
                        <h2 className="title-card-home">Poklon paket "Ženske priče"</h2>
                        <p className="text-card-home">
                            Obradujte ženu koju volite, zahvalite joj i recite nešto lijepo – poklonite joj paket „Ženskih priča“, sa 350 g Premium kafe i posebno dizajniranom šoljicom. Uskoro dostupno u svim većim prodajnim mjestima.
                        </p>
                        <Link to="/zenske-price" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Read more
                            </button>
                        </Link>
                    </div>
                </Col>
                <Col lg={6} className="p-0">
                    <img src={zenskePricePaket} alt="Poklon paket Ženske priče - Omcafe BIH" className="image-card-home" />
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={tarik} alt="Omcafe BIH Tarik Filipović Eespresso Capsule" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">News</h4>
                        <h2 className="title-card-home">Tarik Filipović je novi ambasador brenda</h2>
                        <p className="text-card-home">
                            Glumac i televizijski voditelj Tarik Filipović u narednom periodu biće zaštitno lice OMCAFÉ Espresso
                            Capsula. Počevši od 19. oktobra, Tarik Filipović će biti predstavljen u novoj OMCAFÉ Espresso Capsule
                            marketinškoj kampanji koja nas poziva da „izaberemo svoj užitak“, putem televizijskog, štampanog i
                            digitalnog oglašavanja.
                                </p>
                        <Link to="/tarik-filipovic-novi-brend-ambasador" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                READ MORE
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">New - OMCAFÉ online store</h4>
                        <h2 className="title-card-home">OMCAFÉ online store</h2>
                        <p className="text-card-home">
                            Dear coffee lovers, from now on you can find your favorite OMCAFÉ products on the newly opened web store on our website. For the security of your home, order the desired quantities and expect them soon at your address with cash on delivery. Access the store on our website: <u>shop.omcafe.net</u> and enjoy your shopping.
                                </p>
                        <a href="https://shop.omcafe.net/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Visit the store
                            </button>
                        </a>
                    </div>
                </Col>
                <Col lg={6} className="p-0">
                    <img src={prodavnica} alt="Omcafe BIH" className="image-card-home" />
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={aparat} alt="Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">NEW PRODUCT</h4>
                        <h2 className="title-card-home">OMCAFÉ Espresso Capsule machine</h2>
                        <p className="text-card-home">
                            If you are dedicated to the original aroma of espresso and you would gladly introduce your ritual in home or work ambiance, we enriched the offer with the new product, OMCAFÉ Espresso Capsule machine and capsules. This is all about the alternative to investment for the professional class device affordable to every household characterized by speed, practicality, and consistency in the coffee preparation process. Therefore, let our espresso machine, which produces a drink of stimulating aromas from capsules filled with a perfect ratio of top ingredients in just a few seconds, be in charge of the permanence of your pleasure from now on.
                                    </p>
                        <Link to="/omcafe-espresso-capsule-machine" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                READ MORE
                                    </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">NEW PRODUCT - ORGANIC COFFEE</h4>
                        <h2 className="title-card-home">When nature and body say thank you</h2>
                        <p className="text-card-home">
                            To think of your and environmental wellness, even in the moment of personal joy, has become an imperative part of nourishment long ago. Groceries we consume on a daily basis, many of them produced in distant parts of the world, through long term consumption impact our body, which is why it is important to dedicate special attention to their quality, that is, the way of production. Besides that, the process of production implies not only creation of the product but also the whole trading chain from the manufacturers to consumers, where many participants do not get their fair share of assets. This can especially be applied on poor countries with often unregulated labor rights and even child exploitation.
                                    </p>
                        <Link to="/omcafe-organic-coffee" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                READ MORE
                                        </button>
                        </Link>
                    </div>
                </Col>
                <Col lg={6} className="p-0">
                    <img src={OrganicCoffee} alt="Omcafe BIH" className="image-card-home" />
                </Col>
            </Row>
        </Container>

    )
}

export default AllNewsEn;