import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 156.8 156.8" {...props}>
      <title>{"phone"}</title>
      <g data-name="Layer 2">
        <path
          d="M78.4 0a78.4 78.4 0 1078.4 78.4A78.4 78.4 0 0078.4 0zM54.1 26.3a3.84 3.84 0 012.5-.3 3.77 3.77 0 011.5.9c.6.5 1 1.2 1.5 1.7 2.2 4.2 4.6 9.4 7 15.1.7 1.7 1.4 3.4 2.1 5.2 1 2.5.5 4.8-1.4 5.8-2.6 1.4-3.6 1.8-6 3l-9.9-22.8-2.6-6.2a44 44 0 015.3-2.4zM112 127.7c-2 1.1-5.1 2.9-7.4 2.3a88.71 88.71 0 01-14.7-5.8 101.78 101.78 0 01-13.1-7.6A91 91 0 0156.3 98a107.92 107.92 0 01-22.1-51c-.5-3.5 1.1-5.3 2.8-7.9 2.2-3.4 5.1-5.5 8.3-7.9l.1-.1.1.1 12.1 27.9a7.11 7.11 0 00-2.4 1.3 3.53 3.53 0 00-.3 4c8.3 16 18.3 30.3 32.4 39a3.88 3.88 0 003.8.1 5 5 0 001.2-.8l27.3 19.4a49 49 0 01-7.6 5.6zm10.6-8.4l-28-19.9a6.73 6.73 0 01.7-1.1c2-2.9 3.4-6.3 7.6-4.5 4.9 2 17 11.4 19 13 1.8 1.3 4.3 2.8 4.6 5.1.3 2.1-2.1 5.5-3.9 7.4z"
          fill="#836c60"
          data-name="Layer 1"
        />
      </g>
    </svg>
  )
}

export default SvgComponent