import React from 'react'

// style
import './style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import kafaKojaDise from '../Home/images/kafa-koja-dise.jpg'

const KafaKojaDise = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={kafaKojaDise} alt="Omcafe - Kafa koja dise" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Novosti</h4>
                            <h2 className="title-single-news">Kafa koja „diše“</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Ako ste veliki ljubitelj kafe, sigurno ste primjetili da se na vrećicama za kafu nalazi jednosmjerni  ventil za degasifikaciju koji omogućava zrnima kafe da „dišu“.
                            </p>
                            <p className="text-single-news">
                                <span className="bold-news">Zašto nam je potreban?</span>
                            </p>
                            <p className="text-single-news">
                                Ventil za degasifikaciju je važna komponenta u pakovanja proizvoda.  Kada se zrna kafe prže, proces prženja dovodi do drastične promjene hemijske strukture zrna.  Voda u ćelijama kafe se isparava, te dolazi do otpuštanja velike količine ugljen-dioksida i nekoliko stotina supstanci koje se udružuju u jedinstvenu aromu kafe. U početku je proces prženja egzoterman, a kada postane endoterman dolazi do oslobođenja isparljivih komponenata koje se provlače  kroz razne pukotine i pore iz strukture zrna kafe. Da bi se obezbjedilo što veće zadovoljstvo, kafa se hermetički pakuje odmah nakon prženja jer je osjetljiva na kiseonik odnosno „oksidaciju“ i bez zaštite proizvoda jedinstvena aroma kafe bi ubrzo počela isčezavati.
                            </p>
                            <p className="text-single-news">
                                Da bi se spriječilo pucanje vrećice nastalo oslobađanjem ugljen-dioksida i sačuvala prvobitna svježina kafe, na ambalažu se postavlja ventil za degasifikaciju.
                            </p>
                            <p className="text-single-news">
                                <span className="bold-news">Kako djeluje?</span>
                            </p>
                            <p className="text-single-news">
                                Kada ugljen-dioksid ispušten iz kafe dostigne određeni pritisak, plin pomjera ventil za degasifikaciju iznutra prema van kako bi izbacio višak plina. Kada se pritisak u vrećici smanji na nivo koji nije dovoljan za otvaranje ventila, ventil se automatski zatvara, te sadržaj ambalaže biva potpuno zaštićen od vanjske oksidacije koja izazva isčezavanje arome.
                            </p>
                            <p className="text-single-news">
                                Ventil se automatski otvora samo onda kada je pritisak veći od pritiska izvan vrećice, u suprotnom je zatvoren kako bi spriječio da vanjski vazduh uđe u vrećicu. Na ovaj način nakupljene isparljive komponente mogu pouzdano izaći iz ambalaže istovremeno zadržavajući kiseonik u sebi.
                            </p>
                            <p className="text-single-news">
                                Ventil za degasifikaciju omogućava pakovanju da zadrži oblik, a kafi da ostane svježa kao i kada je bila pržena.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default KafaKojaDise;