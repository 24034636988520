import * as React from "react"

function SvgComponent(props) {
    return (
        <svg viewBox="0 0 156.8 156.8" {...props}>
            <defs>
                <style>{".prefix__cls-1{fill:#836c60}"}</style>
            </defs>
            <title>{"mail"}</title>
            <g id="prefix__Layer_2" data-name="Layer 2">
                <g id="prefix__Layer_1-2" data-name="Layer 1">
                    <path
                        className="prefix__cls-1"
                        d="M91.1 83.7a19.17 19.17 0 01-25.6.2l-.8-.8L37 108.5h82.7L91.9 82.9z"
                    />
                    <path
                        className="prefix__cls-1"
                        d="M78.4 0a78.4 78.4 0 1078.4 78.4A78.4 78.4 0 0078.4 0zm53.9 105.8a10.05 10.05 0 01-10.1 10H34.5a10 10 0 01-10-10V51.1a10 10 0 0110-10h87.8a10.12 10.12 0 0110.1 10v54.7z"
                    />
                    <path
                        className="prefix__cls-1"
                        d="M124.9 103.2V52.1L97.3 77.9l27.6 25.3zM31.9 53.1v50.1l27.4-25.1-27.4-25zM86.1 78.3l32-29.8H37.7l32.9 29.9a11.73 11.73 0 0015.5-.1z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default SvgComponent
