import React from "react";

// components
import { Container, Row, Col } from 'react-bootstrap';

// style
import '../Home/style.css';
import './style.css';


// images
import background1 from "../Home/images/background-1.jpg";

const AboutUsEn = () => {
    return (
        <div className="bg-home">
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <div className="position-card-home">
                            <img src={background1} alt="Omcafe BIH" className="image-card-home" />
                        </div>
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="card-home">
                            <h4 className="subtitle-card-home">OMCAFÉ</h4>
                            <h2 className="title-card-home">Story of uniqueness</h2>
                            <p className="text-card-home custom-margin-bottom">
                                A story of your cup of coffee begins with almost a world tour. In their pursuit for the best varieties, our company experts visit individual coffee farmers with whom they establish both long term and personal relationships. Favorable climate conditions for coffee growing, as well as the long-lasting tradition of cultivation of these plants, predestined us to choose the highlands of the Equator. To make sure the best varieties of raw coffee are picked, our experts taste over 400 test brews. The coffee we import and the way we brew it are inseparably connected one to another. We have developed a special way of grinding and roasting that we constantly improve to make sure the whole spectrum of aromas is released out of our green coffee beans. This process began in the early twenties of last century when the company “Bom Impeks” was established, the first privately owned company in Bosnia and Herzegovina of that time, recognizable for its OMCAFÉ brand.
                                </p>
                            <p className="text-card-home custom-margin-bottom">
                                Today, OMCAFÉ is a part of a multinational company, whose business activities encompass production, distribution, trade and development with branch offices in Banja Luka, Novi Sad, Belgrade, Ljubljana and Vienna.
                                </p>
                            <p className="text-card-home custom-margin-bottom">
                                Through constant improvement of technology, we continuously maintain a high and uniform roasting and grinding level, while our unique way of packing preserves all the stimulating aroma delivered to you in each OMCAFÉ product. Finally, quality experts together with our esteemed customers give a final touch to the recognizable taste.
                                </p>
                            <p className="text-card-home custom-margin-bottom">
                                You are invited to try and savor our and your coffee, because each cup of OMCAFÉ coffee is another “Inspiration for conversation”.
                                </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutUsEn;