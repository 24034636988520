import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 140 140"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st0{fill:#836c60}"}</style>
      <path
        className="prefix__st0"
        d="M70 44c-7.8 0-14.1 6.3-14.1 14.1S62.2 72.2 70 72.2s14.1-6.3 14.1-14.1S77.8 44 70 44z"
      />
      <path
        className="prefix__st0"
        d="M70 0C31.3 0 0 31.3 0 70c0 38.6 31.3 70 70 70 38.6 0 70-31.3 70-70S108.6 0 70 0zm24.1 72.5l-21.7 36.3c-.2.3-.5.6-.8.8-1.1.7-2.6.3-3.2-.8L46.1 72.9c-2.8-4.4-4.2-9.6-4.2-14.8C41.9 42.6 54.5 30 70 30c5.1 0 10 1.4 14.4 4 13.3 7.9 17.7 25.2 9.7 38.5z"
      />
    </svg>
  )
}

export default SvgComponent
