import React from 'react'
import './style.css'
import { Container, Row, Col } from 'react-bootstrap'
import logoFooter from './images/logo-footer.svg'
import { Link } from 'react-router-dom'

import Instagram from './images/icons/Instagram'
import Facebook from './images/icons/Facebook'
import YouTube from './images/icons/YouTube'
import Phone from './images/icons/Phone'
import Location from './images/icons/Location'
import Mail from './images/icons/Mail'

const FooterEn = () => {
    return (
        <div>
            <footer className="footer">
                <Container className="align-container">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={3}>
                            <img className="logo-footer" src={logoFooter} alt="Omcafé logo" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3}>
                            <div className="footer-group">
                                <h3 className="title-footer">Products</h3>

                                <Link to="/traditional-coffee" className="reset-link-style">
                                    <p className="link-footer">Traditional coffee</p>
                                </Link>

                                <Link to="/espresso-coffee" className="reset-link-style">
                                    <p className="link-footer">Espresso coffee</p>
                                </Link>

                                <Link to="/instant-coffee" className="reset-link-style">
                                    <p className="link-footer">Instant coffee</p>
                                </Link>

                                <Link to="/espresso-capsules" className="reset-link-style">
                                    <p className="link-footer">Espresso Capsules</p>
                                </Link>

                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3}>
                            <div className="footer-group">
                                <h3 className="title-footer">OMCAFÉ</h3>
                                <Link to="/about-us" className="reset-link-style">
                                    <p className="link-footer">About us</p>
                                </Link>
                                <Link to="/contact-us" className="reset-link-style">
                                    <p className="link-footer">Contact</p>
                                </Link>
                                <Link to="/news" className="reset-link-style">
                                    <p className="link-footer">News</p>
                                </Link>                            
                                <a href="https://shop.omcafe.net/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                    <p className="link-footer">Web shop</p>
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3}>
                            <h3 className="title-footer">CONTACT</h3>
                            <a className="reset-link-style" href="mailto:info@bomimpeks.com">
                                <p className="link-footer">
                                    <Mail className="icon-footer" />
                                    info@bomimpeks.com
                                </p>
                            </a>
                            <a className="reset-link-style" href="tel:+387 51 434 130">
                                <p className="link-footer">
                                    <Phone className="icon-footer" />
                                    +387 51 434 130
                                </p>
                            </a>
                            <a className="reset-link-style" href="tel:+387 51 434 130">
                                <p className="link-footer">
                                    <Location className="icon-footer" />
                                    Jovana Bijelića bb Banja Luka
                                </p>
                            </a>
                            <a className="reset-link-style" href="https://www.instagram.com/omcafebih/" target="_blank" rel="noopener noreferrer">
                                <p className="link-footer">
                                    <Instagram className="icon-footer" />
                                    Instagram
                                </p>
                            </a>
                            <a className="reset-link-style" href="https://www.facebook.com/omcafebih/" target="_blank" rel="noopener noreferrer">
                                <p className="link-footer">
                                    <Facebook className="icon-footer" />
                                    Facebook
                                </p>
                            </a>
                            <a className="reset-link-style" href="https://www.youtube.com/channel/UCNUbzQtSye_whlbQT32MaKw" target="_blank" rel="noopener noreferrer">
                                <p className="link-footer">
                                    <YouTube className="icon-footer" />
                                    YouTube
                                </p>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <div className="copyright">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} className="col-copyright-left">
                            <span className="text-copyright">2020 © OMCAFÉ. Bom Impeks, d.o.o. All rights reserved.</span>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} className="col-copyright-right">
                            <span className="text-copyright">Made with love by <span className="made-by-copyright"><a className="reset-link-style" href="https://borndigital.ba/" target="_blank" rel="noopener noreferrer">borndigital</a></span></span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default FooterEn;