import React from 'react'

// style
import './style.css'

// components
import { Container, Row, Col } from 'react-bootstrap'

// images
import tradicionalna from './images/tradicionalna.jpg'
import Coffee from '../../assets/images/intro/Coffee'
import shadow from '../../assets/images/intro/shadow.jpg'

// products
import premium from './images/products/Omcafe Premium Kafa.png'
import rio from './images/products/Omcafe Rio kafa.png'
import mirisJutraBez from './images/products/Omcafe Miris Jutra Bez Secera.png'
import mirisJutraSa from './images/products/Omcafe Miris Jutra Sa Secerom.png'
import bio from './images/products/Omcafe Organic Bio 2.png'

const TradicionalnaEn = () => {
    return (
        <>
            <div>
                <img className="intro-image-tradicionalna" src={tradicionalna} alt="omcafe" />
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} className="text-center">
                        <p className="text-intro-tradicionalna">
                            We are what we drink – OMCAFÉ lovers know it best. In each cup of their beloved beverage, every day they find the joy of sharing, tenderness, peace and consolation, they feel pleasure of conversation that feeds the soul and the coffee that feeds the body. OMCAFÉ is a friend you always welcome with joy, and who returns the favor with loyalty and quality. That is why enjoying OMCAFÉ beverages means to have a great taste in life, the art of savoring the good, traditional values.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Coffee className="beans-intro-image-tradicionalna" />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <img className="shadow-intro-image-tradicionalna" src={shadow} alt="omcafe" />
                    </Col>
                </Row>
            </Container>

            <div className="section-tradicionalna premium-bg">
                <Container>
                    <Row className="column-reverse-section-tradicionalna">
                        <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-section-tradicionalna">
                            <div className="align-content-section-tradicionalna">
                                <h2 className="title-section-tradicionalna">OMCAFÉ Premium</h2>
                                <p className="text-section-tradicionalna">Recognizable for its scent and taste, the most welcome guest of family gatherings and a part of every gift prepared with love. A unique harmony of aromas comes from carefully blended arabica, belonging to a group of high-class beans cultivated in rainy and shaded hills of Latin America, and robusta, brought to you from hot areas, characterized by consistency and a high concentration of caffeine that creates a strong intensive taste.</p>
                                <button className="button-section-tradicionalna premium-button">ADD TO SHOPPING CART</button>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-section-tradicionalna">
                            <img className="image-section-tradicionalna" src={premium} alt="omcafe" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col>
                        <div className="card-tradicionalna organic-bg">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-card-tradicionalna">
                                    <img className="image-card-tradicionalna" src={bio} alt="omcafe" />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-tradicionalna">
                                    <div className="align-content-card-tradicionalna">
                                        <h2 className="title-card-tradicionalna">OMCAFÉ Premium Organic Coffee</h2>
                                        <p className="text-card-tradicionalna">
                                            Traditional mixture of roast organic ground arabica and robusta of 100% organic manufacture. Created by principles of organic cultivation and distributed through certified chain of fair trade - Fair Trade (Fair for Life) a 100% of all ingredients, and packed in protective atmosphere. From the plantation to your cup, each detail brought to perfection.
                                        </p>
                                        <button className="button-card-tradicionalna organic-button">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="section-tradicionalna rio-bg">
                <Container>
                    <Row className="column-reverse-section-tradicionalna">
                        <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-section-tradicionalna">
                            <div className="align-content-section-tradicionalna">
                                <h2 className="title-section-tradicionalna">OMCAFÉ Rio</h2>
                                <p className="text-section-tradicionalna">Our traditional domestic coffee produced in two varieties: OMCAFÉ Premium and OMCAFÉ Rio Economy. The main difference between them lies in a more intensive taste of OMCAFÉ Premium when compared to OMCAFÉ Rio. This pleases lovers of softer and milder coffee aromas. Each spoon of this fine ground coffee blends all the riches of Latin America and Africa, and their unity creates a true, tiny aromatic miracle in front of you.</p>
                                <button className="button-section-tradicionalna premium-button">ADD TO SHOPPING CART</button>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-section-tradicionalna">
                            <img className="image-section-tradicionalna" src={rio} alt="omcafe" />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row>
                    <Col>
                        <div className="card-tradicionalna miris-bez-bg custom-padding-left">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-card-tradicionalna">
                                    <img className="image-card-tradicionalna custom-image-size-card-tradicionalna" src={mirisJutraBez} alt="omcafe" />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-tradicionalna">
                                    <div className="align-content-card-tradicionalna">
                                        <h2 className="title-card-tradicionalna">SUGAR-FREE MIRIS JUTRA</h2>
                                        <p className="text-card-tradicionalna">
                                            Morning shows the day, and sugar-free coffee good habits. That is why coffee lovers who enjoy its original pungent taste can prepare their traditional beverage fast and easy. “Miris jutra”, sugar-free, is you pocket edition of pleasure.
                                        </p>
                                        <button className="button-card-tradicionalna miris-bez-button">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <div className="card-tradicionalna miris-sa-bg custom-padding-left last-card-tradicionalna">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-card-tradicionalna">
                                    <img className="image-card-tradicionalna custom-image-size-card-tradicionalna" src={mirisJutraSa} alt="omcafe" />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-tradicionalna">
                                    <div className="align-content-card-tradicionalna">
                                        <h2 className="title-card-tradicionalna">MIRIS JUTRA WITH SUGAR</h2>
                                        <p className="text-card-tradicionalna">
                                            Nothing smells as good as a fresh cup of coffee in the morning. When your daily routine picks up the pace from an early morning, there is no need to give up your favorite habit – enjoying the aroma of traditional black coffee. “Miris jutra” in a bag is an elegant way to stay loyal to your tradition under any circumstances.
                                        </p>
                                        <button className="button-card-tradicionalna miris-sa-button">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default TradicionalnaEn;
