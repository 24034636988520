import React, { useEffect } from 'react'
import './style.css'
import NavigationEn from '../Navigation/NavigationEn';
import FooterEn from '../Footer/FooterEn';

const Layout = (props) => {
    useEffect(() => { window.scrollTo(0, 0) });
    const { children } = { ...props }
    return (
        <>
            <NavigationEn />
                <div className="layout">
                    {children}
                </div>
            <FooterEn />
        </>
    )
}

export default Layout