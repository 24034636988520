import React from 'react'

// style
import './style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import aparat from '../Home/images/Omcafe espresso capsule aparat.jpg'
import { Link } from 'react-router-dom';

const NewsAparatEn = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={aparat} alt="Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">New Product</h4>
                            <h2 className="title-single-news">OMCAFÉ Espresso Capsule machine</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                If you are dedicated to the original aroma of espresso and you would gladly introduce your ritual in home or work ambiance, we enriched the offer with the new product, OMCAFÉ Espresso Capsule machine and capsules. This is all about the alternative to investment for the professional class device affordable to every household characterized by speed, practicality, and consistency in the coffee preparation process. Therefore, let our espresso machine, which produces a drink of stimulating aromas from capsules filled with a perfect ratio of top ingredients in just a few seconds, be in charge of the permanence of your pleasure from now on.
                            </p>
                            <p className="text-single-news">
                                OMCAFÉ Espresso Capsule machine, <span className="italic-news"> compatible with Nespresso capsules</span>, thanks to the small dimensions, modern and simple design available in three colors, easily fits in every kitchen. With minimum maintenance requirements, your OMCAFÉ Espresso Capsule machine will maintain a constant beverage quality for a long time.
                            </p>
                            <p className="text-single-news">
                                <span className="bold-news underline">

                                    <Link to="/capsule" className="reset-link-style">OMCAFÉ Espresso Capsules </Link>


                                </span> come in six different aromas, intensities, and caffeine amounts: Firenze, Venezia, Decaffeinato, Forte, Corposo and Strong each containing small doses of quality blends. The process of choosing, roasting and processing of coffee beans is completed in the equivalent doses that make everyday coffee preparation easier and ensure that each sip tastes like the previous one.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default NewsAparatEn;