import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 142 142" {...props}>
      <path
        d="M71 1C32.3 1 1 32.3 1 71s31.3 70 70 70 70-31.3 70-70S109.7 1 71 1zm15.4 70H76.1v41.1H60.7V71H50.4V58.1h10.3V45.3c0-8.5 6.9-15.4 15.4-15.4h10.2v12.9h-5.1c-2.8 0-5.1 2.3-5.1 5.1v10.2l15.4.1L86.4 71z"
        fill="#836c60"
      />
    </svg>
  )
}

export default SvgComponent
