import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/index.css';

// screens

import Home from './screens/Home';
import Tradicionalna from './screens/Tradicionalna';
import Espresso from './screens/Espresso';
import Instant from './screens/Instant';
import Capsule from './screens/Capsule';
import AboutUs from './screens/AboutUs';
import Contact from './screens/Contact';
import AllNews from './screens/AllNews';
import NewsOrganskaKafa from './screens/News/NewsOrganskaKafa';
import NewsAparat from './screens/News/NewsAparat';
import Layout from './components/Layout/Layout';
import TarikFilipovicNoviBrendAmbasador from './screens/News/TarikFilipovicNoviBrendAmbasador';

import ZenskePrice from './screens/ZenskePrice';
import Marijana from './screens/ZenskePrice/Marijana';
import Elena from './screens/ZenskePrice/Elena';
import Isidora from './screens/ZenskePrice/Isidora';
import Emina from './screens/ZenskePrice/Emina';
import Iva from './screens/ZenskePrice/Iva';
import Nastja from './screens/ZenskePrice/Nastja';
import Emilija from './screens/ZenskePrice/Emilija';
import Galina from './screens/ZenskePrice/Galina';
import Ines from './screens/ZenskePrice/Ines';
import Lejla from './screens/ZenskePrice/Lejla';

import KafaKojaDise from './screens/News/KafaKojaDise';

const App = () => {
	return (
		<React.Fragment>
			<Router>
				<Switch>
					<Layout>
						{/* en */}
						<Route key="Home" path="/" exact component={Home} />
						<Route key="Tradicionalna" path="/traditional-coffee" exact component={Tradicionalna} />
						<Route key="Espresso" path="/espresso-coffee" exact component={Espresso} />
						<Route key="Instant" path="/instant-coffee" exact component={Instant} />
						<Route key="Capsule" path="/espresso-capsules" exact component={Capsule} />
						<Route key="AboutUs" path="/about-us" exact component={AboutUs} />
						<Route key="Contact" path="/contact-us" exact component={Contact} />
						<Route key="AllNews" path="/news" exact component={AllNews} />
						<Route
							key="NewsOrganskaKafa"
							path="/omcafe-organic-coffee"
							exact
							component={NewsOrganskaKafa}
						/>
						<Route key="NewsAparat" path="/omcafe-espresso-capsule-machine" exact component={NewsAparat} />
						<Route
							key="TarikFilipovicNoviBrendAmbasador"
							path="/tarik-filipovic-novi-brend-ambasador"
							exact
							component={TarikFilipovicNoviBrendAmbasador}
						/>

						<Route key="ZenskePrice" path="/zenske-price" exact component={ZenskePrice} />
						<Route key="Marijana" path="/marijana" exact component={Marijana} />
						<Route key="Elena" path="/elena" exact component={Elena} />
						<Route key="Isidora" path="/isidora" exact component={Isidora} />
						<Route key="Emina" path="/emina" exact component={Emina} />
						<Route key="Iva" path="/iva" exact component={Iva} />
						<Route key="Nastja" path="/nastja" exact component={Nastja} />
						<Route key="Emilija" path="/emilija" exact component={Emilija} />
						<Route key="Galina" path="/galina" exact component={Galina} />
						<Route key="Ines" path="/ines" exact component={Ines} />
						<Route key="Lejla" path="/lejla" exact component={Lejla} />

						<Route key="KafaKojaDise" path="/kafa-koja-dise" exact component={KafaKojaDise} />
						
					</Layout>
				</Switch>
			</Router>
		</React.Fragment>
	);
};

export default App;
