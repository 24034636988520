import * as React from "react"

function SvgComponent(props) {
    return (
        <svg viewBox="0 0 11.75 11.75" {...props}>
            <g data-name="Layer 2">
                <path
                    d="M3.61 11.29A5.89 5.89 0 011.73 10 6 6 0 01.46 8.15 5.62 5.62 0 010 5.88a5.58 5.58 0 01.46-2.27A6 6 0 013.6.46 5.62 5.62 0 015.87 0a5.58 5.58 0 012.27.46A5.94 5.94 0 0110 1.73a6.06 6.06 0 011.27 1.88 5.58 5.58 0 01.46 2.27 5.62 5.62 0 01-.46 2.27A6 6 0 0110 10a5.85 5.85 0 01-1.87 1.27 5.62 5.62 0 01-2.27.46 5.58 5.58 0 01-2.25-.44zm7.71-5.11H.43v-.61h10.89zM7.9 10.72a5.37 5.37 0 001.68-1.14 5.3 5.3 0 001.13-1.67 5 5 0 00.42-2 5 5 0 00-.42-2 5.23 5.23 0 00-1.13-1.74A5.35 5.35 0 007.9 1a5 5 0 00-2-.41 5 5 0 00-2 .41 5.3 5.3 0 00-1.73 1.17A5.23 5.23 0 001 3.85a5 5 0 00-.41 2 5 5 0 00.41 2 5.36 5.36 0 002.81 2.81 5.21 5.21 0 004.05 0zm-3.7-7.3A6.89 6.89 0 012.77 3a3.87 3.87 0 01-1-.61L2.19 2a4 4 0 00.94.52 6.25 6.25 0 001.24.31A8.85 8.85 0 005.88 3a8.81 8.81 0 001.5-.11 6.07 6.07 0 001.24-.31A4 4 0 009.56 2l.44.4A3.81 3.81 0 019 3a7.26 7.26 0 01-1.43.41 10 10 0 01-1.68.14 9.84 9.84 0 01-1.69-.13zm3.36 4.94A8 8 0 019 8.77a3.82 3.82 0 011 .62l-.47.38a3.64 3.64 0 00-.94-.51 6.06 6.06 0 00-1.24-.32 10.35 10.35 0 00-3 0 6.24 6.24 0 00-1.24.32 3.64 3.64 0 00-.94.51l-.47-.38a3.88 3.88 0 011.05-.62 7.56 7.56 0 011.45-.41 9.84 9.84 0 011.68-.14 10 10 0 011.68.14zm-2.79 2.75A3.6 3.6 0 013.84 10a7 7 0 01-.63-1.79A10 10 0 013 5.89a10.12 10.12 0 01.23-2.28 7 7 0 01.63-1.79A3.6 3.6 0 014.77.66 1.74 1.74 0 015.88.25 1.69 1.69 0 017 .66a3.6 3.6 0 01.93 1.16 7 7 0 01.63 1.79 10.12 10.12 0 01.23 2.28 10.64 10.64 0 01-.23 2.27A7.35 7.35 0 017.91 10 3.6 3.6 0 017 11.11a1.79 1.79 0 01-1.1.41 1.8 1.8 0 01-1.13-.41zM5 1.19a3.51 3.51 0 00-.71 1.11 7.53 7.53 0 00-.51 1.62 10.31 10.31 0 00-.18 2 10 10 0 00.18 1.93 7.81 7.81 0 00.51 1.63A3.51 3.51 0 005 10.56a1.22 1.22 0 00.86.41 1.24 1.24 0 00.86-.41 3.67 3.67 0 00.72-1.11A7.81 7.81 0 008 7.82a10 10 0 00.18-1.93 10.31 10.31 0 00-.18-2 7.53 7.53 0 00-.54-1.59 3.67 3.67 0 00-.72-1.11 1.23 1.23 0 00-.86-.4 1.21 1.21 0 00-.88.4zM6.18.33v11.08h-.6V.33z"
                    fill="#c59361"
                    data-name="Layer 1"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
