import React from "react";

// components
import { Container, Row, Col } from 'react-bootstrap';

// style
import '../Home/style.css';
import './style.css';

// images
import map from './images/mapa.jpg'

// icons
import Instagram from '../../components/Footer/images/icons/Instagram'
import Facebook from '../../components/Footer/images/icons/Facebook'
import YouTube from '../../components/Footer/images/icons/YouTube'
import Phone from '../../components/Footer/images/icons/Phone'
import Location from '../../components/Footer/images/icons/Location'
import Mail from '../../components/Footer/images/icons/Mail'

const ContactEn = () => {
    return (
        <div className="bg-home">
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <div className="position-card-home">
                            <img src={map} alt="Omcafe BIH" className="image-card-home" />
                        </div>
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="card-home">
                            <h4 className="subtitle-card-home">Contact</h4>
                            <h2 className="title-card-home title-contact">Contact information</h2>
                            <Row>
                                <Col sm={12}>
                                    <a className="reset-link-style" href="https://goo.gl/maps/RSUe1sbWKJ3SsGWr6">
                                        <div className="form-inline custom-margin-bottom-contact"><Location className="icon-contact" /><span className="span-constact">
                                            Jovana Bijelića bb 78000 Banja Luka</span></div>
                                    </a>
                                </Col>
                                <Col sm={12}>
                                    <a className="reset-link-style" href="mailto:info@bomimpeks.com">
                                        <div className="form-inline custom-margin-bottom-contact"><Mail className="icon-contact" /><span className="span-constact">info@bomimpeks.com</span></div>
                                    </a>
                                </Col>
                                <Col sm={12}>
                                    <a className="reset-link-style" href="tel:+387 51 434 130">
                                        <div className="form-inline custom-margin-bottom-contact"><Phone className="icon-contact" /><span className="span-constact">+387 51 434 130</span></div>
                                    </a>
                                </Col>
                                <Col sm={12}>
                                    <a className="reset-link-style" href="https://www.instagram.com/omcafebih/" target="_blank" rel="noopener noreferrer">
                                        <div className="form-inline custom-margin-bottom-contact"><Instagram className="icon-contact" /><span className="span-constact">Instagram</span></div>
                                    </a>
                                </Col>
                                <Col sm={12}>
                                    <a className="reset-link-style" href="https://www.facebook.com/omcafebih/" target="_blank" rel="noopener noreferrer">
                                        <div className="form-inline custom-margin-bottom-contact"><Facebook className="icon-contact" /><span className="span-constact">Facebook</span></div>
                                    </a>
                                </Col>
                                <Col sm={12}>
                                    <a className="reset-link-style" href="https://www.youtube.com/channel/UCNUbzQtSye_whlbQT32MaKw" target="_blank" rel="noopener noreferrer">
                                        <div className="form-inline custom-margin-bottom-contact"><YouTube className="icon-contact" /><span className="span-constact">YouTube</span></div>
                                    </a>
                                </Col>
                            </Row>





                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactEn;