import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import nastja from '../Home/images/nastja.jpg'

const Nastja = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={nastja} alt="Ženske priče - Nastja - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Nastja</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Baviću će plesom. Imaću cipele presvučene bež satenom, sa španskom petom i đonom od fine brušene kože. Imaću svog partnera. Biće najljepši i najzgodniji plesač kojeg su dvorane ikad vidjele. Imaće gustu crnu kosu začešljanu unazad, košulje će mu na plećima biti zategnute poput brodskog jedra, i govoriće riječi sa kotrljajućim „r“, jer to je tako šarmantno. Vjerovaću mu sve. Vjerovaću kad mi kaže da žurim sa korakom između dva otkucaja rumbe i onda kad mislim da sam pogodila tempo. Vjerovaću kad kaže da mi ne stoji perje i onda kad se najbrižljivije okitim. Vjerovaću njegovoj obrvi kad spontano namreška čelo, njegovoj šaci kad me klizanjem prsta okrene lijevo iako sam namjeravala desno. I kad kaže da ćemo publici biti omiljeni par i da će nama najjače pljeskati, i da ćemo poslije biti preumorni da se šetamo pod zvijezdama. Na mom plesaču svi će mi zavidjeti. Diviće se njegovoj sjajnoj koži, njegovim uglađenim manirima i bisernom osmijehu.
                            </p>
                            <p className="text-single-news">
                                Znam te poglede – mislite da biste takvom sve oprostile. Dovoljno je jednom da vas pogleda iskosa i namigne dok vas miluje po ruci, i već zaboravite šta ste upravo ljutito htjele da mu prebacite. Ali znate, moja ljubav prema njemu neće biti u vještom izbjegavanju sukoba, niti zadivljenost turiste pred eksponatom. Nije čak ni važno da li će moj plesač postati moj muž, ili bar prijatelj, ili ništa od toga. Ja ću ga voljeti najviše zato što ću mu vjerovati najviše. To je sve što mi je važno, a kad budete pravili korake u mojim cipelama kao što ću ih ja praviti tiho klizeći preko podijuma oivičenog ogledalima, možda ćete ponešto razumjeti. Vi ne možete znati šta će on biti meni, sve dok ne zalutate u onoliko stranputica u koliko sam ja bila dovedena: lijepim riječima i još ljepšim manirima. Iz tih iskrivljenih ogledala vide se samo pričini karaktera i distorzije misli.
                            </p>
                            <p className="text-single-news">
                                Ali sada već znam, nakon toliko pogrešnih ritmova, da prava radost počinje tamo gdje raste vjera u čovjeka. Ona kristalno jasna vjera u dobru namjeru, taj rijedak dragulj zajedničarenja. Zato ću i ja biti radosna do zvijezda, ma kakve figure od sebe pravila, znajući da će tu biti neko dovoljno hrabar da stane iza svog odraza u ogledalu. Jer, šta ima veće od Čovjeka?
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Nastja;