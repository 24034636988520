import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import emina from '../Home/images/emina.jpg'

const Emina = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={emina} alt="Ženske priče - Emina - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Emina</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                <i>Mina, Mina, daj nam alve!</i> trče komšijska djeca za mnom niz ulicu, a ja žurim sa prepunim cekerima namirnica u pulsirajućim rukama. Koliko će ovaj dan biti težak od nula do 'zašto uopšte prodajem kolače'? Gledam kraj sebe malu, ćutljivu Edisu, komšijinu mezimicu što svakog jutra maršira žustrim korakom kraj mene zavirujući kroz ručke mojih tovara šećera, jaja i maslaca, štipkajući kese sa orasima ne bi li izvukla koji za sebe kad zadihana stanem da otpozdravim nekom preko ograde. Pa, Edisa, koliko koraka treba još da pređeš dok ne ukoračiš u kuhinju sa mnom da zajedno miješamo kremu za sladoled? Ni sama ne znam kako to nastaje – <i>znanje</i>.
                            </p>
                            <p className="text-single-news">
                                Moja prva tura đuzleme zagorjela je jer sam se zaigrala sa kućnom mačkom. Drugu sam dabome presjedila policijski piljeći u rernu, na trećoj sam se već durila kad mi je mati pomogla da razvučem kore preko astala. Dok bih, sluhom sad već malog profesionalca, pazila na zvuk krčkanja uzavrele agde na platni, liskala bih kažiprst prelistavajući porodičnu svetu knjigu recepata. Mati bi ćutke, zamišljena, umakala lokum u gorku kafu, a moja mašta bi se uzburkana gubila u slikama magijskih postupaka koje su mi se razvijale pred očima. Lupanje sluzavih bjelanaca u snijeg, bujanje kvasca u pečurku na mlijeku, cvrkutanje mjehurića u tijestu pod krpom, mućenje nad parom, potapanje u ledenu vodu, brzopotezno prevrtanje naopačke iz pleha, i desetine drugih, čudesnih radnji za stvaranje poslastica od čijih imena zastaje dah: ružice i krempite, torta arabija i dunavski valovi, damen kapric i princes-krofne... Koliko nježnosti, poetike i geografije spakovanih u zalogajima opipljive životne radosti!
                            </p>
                            <p className="text-single-news">
                                Kuhinja se u meni od prvog susreta ugnijezdila kao mjesto opijajuće mistike, gdje obitavaju dobri duhovi mogućnosti, a moje ruke postaju mađioničarske palice. Da li ruke porađaju <i>znanje</i>? Ja sam kćerka sarača, unuka koradžije, i majka bezbroj rumenih oblutaka od tijesta, kremova i korica. Svima su nam ruke život opredijelile. Da li će biti tako i s tobom, malena Edisa? Samo nastavi da držiš korak sa svojim željama. Ruke će te vinuti tamo gdje mašta poželi. Vjeruj, probali smo.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Emina;