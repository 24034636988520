import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import emilija from '../Home/images/emilija.jpg'

const Emilija = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={emilija} alt="Ženske priče - Emilija - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Emilija</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Autobusni kolodvor u Zagrebu u tri sata ujutro najuzbudljivije je mjesto na kojem sam bila tog ljeta. U redu, nisu beskrajno zabavni pospani putnici koji vuku izanđale torbetine tamo-amo pod žmiravim neonskim lampama. Ali prelaz noći u jutro čudnovato je vrijeme kad se stvari odvijaju logikom neshvatljivom danu. Izgužvana autobuskom vožnjom iz Banjaluke, prispjela sam u kafe nadomak centralne čekaonice, pomirena sa tri beskrajna sata koja su me dijelila od linije za Trst. U takvim trenucima čovjek vapi za odsutnošću svakog zbivanja, ma čak i susreta s nečijim pogledom koji bi iziskivao oživljavanje pažnje.
                            </p>
                            <p className="text-single-news">
                                Odustavši od lošeg espresa, baš sam bila spremna da kao mačka kraj vatre uronim u sladunjavu dremku, kad me trže muški glas: „Oprostite, da li je slobodno?“ To je bio jedan od onih trenutaka kad iznutra zavrištite: „Naravno da nije slobodno, zar ne vidiš da ja ovdje sjedim i da mi je lijepo, i zašto si uopšte od svih stolova odlučio da sjedneš <i>baš za moj</i>?!“, a vaša usta ravnodušno izgovaraju: „Naravno, izvolite“, jer vaspitanje vas opominje da vam otac nije kupio kafanu na kolodvoru, a savjest se pribojava da nije kakva nesreća natjerala neznanca da upita baš za vaš jadni sto. Od tog momenta znala sam da više neću imati luksuz ćutanja, ali nisam imala predstavu u kakvu paučinu narativa ću biti upletena.
                            </p>
                            <p className="text-single-news">
                                Moj sagovornik, naime, bio je pedesetogodišnji udovac sa teškom životnom pričom i nije zazirao da mi je ispriča do pojedinosti. Najednom, povjerljivo me pogleda, sasvim tiho upitavši: „Znate li vi išta o putovanju duša?“. Prije nego što sam uspjela da povežem receptor sluha sa mozgom, otpočelo je izlaganje o ezoteričnim eksperimentima koje je neznanac vršio nad sobom. Isprva pod prismotrom svog duhovnog učitelja, a u posljednje vrijeme i samostalno. „Znate, to su vrlo opasne stvari. Možete da izađete iz sebe, a opet, ako ne znate pravi put, možda nikad nećete uspjeti da se vratite.“ Pomućenih misli i otupljenih čula lupih se po čelu da moj autobus za Trst samo što nije pošao, te zgrabih svoje stvari, bez pozdrava đipih i uputih se ka peronu. Tamo se već tiskala gomila putnika, a usred njih je čučao, pred ogromnom kartom raširenoj po asfaltu, crnomanjast mladić. Dok sam pokušala da ga zaobiđem, povukao me je za rukav: „Sarahevo?“ Kud si ti iz Španije jutros pošao za Sarajevo? Odmahnuh mu glavom i kažiprstom, on spakova kartu i otrča za drugim autobusom. I ako uspijem izaći iz sebe, možda neću umjeti pronaći put da se vratim.
                            </p>
                            <p className="text-single-news">
                                Bilo mi je dosta svega za to jutro. Već je svitalo kad smo pošli ka Italiji, a ja sam se u tom procjepu između tmine i svjetla pitala: kako je to tražiti, a ne naći sebe? Grad pronađete, ali dušu nikad?
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Emilija;