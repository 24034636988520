import React, { useState } from "react";

// components
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

// style
import './style.css';

// images
import background1 from "./images/background-1.jpg";
import background2 from "./images/background-2.jpg";
import klubLjubiteljaOmcafe from "./images/klub-lubitelja-omcafe.jpg";
import OrganicCoffee from "./images/Omcafe-organic-coffee.jpg";
import aparat from './images/Omcafe espresso capsule aparat.jpg'
import prodavnica from './images/Omcafe online prodavnica.jpg'
import tarik from './images/Omcafe Espresso Capsule - Tarik Filipovic.jpg'
import zenskePricePaket from './images/poklon-paket-omcafe-zenske-price.jpg'
import kafaKojaDise from './images/kafa-koja-dise.jpg'

// icons
import Close from '../../components/Navigation/images/Close'

const HomeEn = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="bg-products">
                <Container fluid>
                    <Row className="scroll-product-home">
                        <Col xs={12} sm={12} md={12} lg={3} className="p-0 col-product-home">
                            <div className="product-home">
                                <Link to="/traditional-coffee" className="reset-link-style">
                                    <h1 className="title-product-home">Traditional</h1>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} className="p-0  col-product-home">
                            <div className="product-home">
                                <Link to="/espresso-coffee" className="reset-link-style">
                                    <h1 className="title-product-home">Espresso</h1>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} className="p-0  col-product-home">
                            <div className="product-home">
                                <Link to="/instant-coffee" className="reset-link-style">
                                    <h1 className="title-product-home">Instant</h1>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} className="p-0  col-product-home">
                            <div className="product-home">
                                <Link to="/espresso-capsules" className="reset-link-style">
                                    <h1 className="title-product-home">Capsules</h1>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="bg-home">
                <Container fluid>
                    <Row>
                        <Col lg={6} className="p-0">
                            <div className="position-card-home">
                                <img src={background1} alt="Omcafe BIH" className="image-card-home" />
                            </div>
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">OMCAFÉ</h4>
                                <h2 className="title-card-home">Story of uniqueness</h2>
                                <p className="text-card-home">
                                    A story of your cup of coffee begins with almost a world tour. In their pursuit for the best varieties, our company experts visit individual coffee farmers with whom they establish both long term and personal relationships. Favorable climate conditions for coffee growing, as well as the long-lasting tradition of cultivation of these plants, predestined us to choose the highlands of the Equator. To make sure the best varieties of raw coffee are picked, our experts taste over 400 test brews. The coffee we import and the way we brew it are inseparably connected one to another. We have developed a special way of grinding and roasting that we constantly improve to make sure the whole spectrum of aromas is released out of our green coffee beans. This process began in the early twenties of last century when the company “Bom Impeks” was established, the first privately owned company in Bosnia and Herzegovina of that time, recognizable for its OMCAFÉ brand.
                                    </p>
                                <Link to="/about-us" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Read more
                                        </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="column-reverse-card-home">
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">OMCAFÉ current events</h4>
                                <h2 className="title-card-home">News that smell of coffee</h2>
                                <p className="text-card-home">
                                    Your love for coffee never changes, but the world of production and placement of new, exciting caffeine combination is born right next to you. Why don’t you enrich your favorite habit with some surprising news? Follow Omcafé news and be up to date with our current events.
                                    </p>
                                <Link to="/news" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Show news
                                        </button>
                                </Link>
                            </div>
                        </Col>
                        <Col lg={6} className="p-0">
                            <img src={background2} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className="p-0">
                            <img src={klubLjubiteljaOmcafe} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">OMCAFÉ NEWSLETTER</h4>
                                <h2 className="title-card-home">Fan club – the place that offers more</h2>
                                <p className="text-card-home">
                                    Excellence is not only in discovering the top coffee blends but also in striving to make it available to as many fans of this aromatic beverage as possible. That is why we started the Fan Club, a community in which news about new products, promotions and shopping benefits spread like the smell of freshly brewed coffee. So far, we have gathered more than 20,000 members loyal to Omcafé quality. Click the button below and become part of our community.
                                    </p>
                                <button type="button" className="button-card-home" onClick={handleShow}>
                                    I WANT TO BECOME A MEMBER
                                    </button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className="p-0">
                            <img src={kafaKojaDise} alt="Omcafe - Kafa koja diše" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novosti</h4>
                                <h2 className="title-card-home">Kafa koja „diše“</h2>
                                <p className="text-card-home">
                                    Ako ste veliki ljubitelj kafe, sigurno ste primjetili da se na vrećicama za kafu nalazi jednosmjerni ventil za degasifikaciju koji omogućava zrnima kafe da „dišu“.
                                </p>
                                <Link to="/kafa-koja-dise" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Nastavi čitanje
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="column-reverse-card-home">
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novosti</h4>
                                <h2 className="title-card-home">Poklon paket "Ženske priče"</h2>
                                <p className="text-card-home">
                                    Obradujte ženu koju volite, zahvalite joj i recite nešto lijepo – poklonite joj paket „Ženskih priča“, sa 350 g Premium kafe i posebno dizajniranom šoljicom.
                                    Uskoro dostupno u svim većim prodajnim mjestima.
                                </p>
                                <Link to="/zenske-price" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Pročitaj priče
                                    </button>
                                </Link>
                            </div>
                        </Col>
                        <Col lg={6} className="p-0">
                            <img src={zenskePricePaket} alt="Poklon paket Ženske priče - Omcafe BIH" className="image-card-home" />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className="p-0">
                            <img src={tarik} alt="Omcafe BIH Tarik Filipović Eespresso Capsule" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">News</h4>
                                <h2 className="title-card-home">Tarik Filipović je novi ambasador brenda</h2>
                                <p className="text-card-home">
                                    Glumac i televizijski voditelj Tarik Filipović u narednom periodu biće zaštitno lice OMCAFÉ Espresso
                                    Capsula. Počevši od 19. oktobra, Tarik Filipović će biti predstavljen u novoj OMCAFÉ Espresso Capsule
                                    marketinškoj kampanji koja nas poziva da „izaberemo svoj užitak“, putem televizijskog, štampanog i
                                    digitalnog oglašavanja.
                                </p>
                                <Link to="/tarik-filipovic-novi-brend-ambasador" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        READ MORE
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="column-reverse-card-home">
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">New - OMCAFÉ online store</h4>
                                <h2 className="title-card-home">OMCAFÉ online store</h2>
                                <p className="text-card-home">
                                    Dear coffee lovers, from now on you can find your favorite OMCAFÉ products on the newly opened web store on our website. For the security of your home, order the desired quantities and expect them soon at your address with cash on delivery. Access the store on our website: <u>shop.omcafe.net</u> and enjoy your shopping.
                                </p>
                                <a href="https://shop.omcafe.net/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Visit the store
                                    </button>
                                </a>

                            </div>
                        </Col>
                        <Col lg={6} className="p-0">
                            <img src={prodavnica} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className="p-0">
                            <img src={aparat} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">NEW PRODUCT</h4>
                                <h2 className="title-card-home">OMCAFÉ Espresso Capsule machine</h2>
                                <p className="text-card-home">
                                    If you are dedicated to the original aroma of espresso and you would gladly introduce your ritual in home or work ambiance, we enriched the offer with the new product, OMCAFÉ Espresso Capsule machine and capsules. This is all about the alternative to investment for the professional class device affordable to every household characterized by speed, practicality, and consistency in the coffee preparation process. Therefore, let our espresso machine, which produces a drink of stimulating aromas from capsules filled with a perfect ratio of top ingredients in just a few seconds, be in charge of the permanence of your pleasure from now on.
                                    </p>
                                <Link to="/omcafe-espresso-capsule-machine" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        READ MORE
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="column-reverse-card-home">
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">NEW PRODUCT - ORGANIC COFFEE</h4>
                                <h2 className="title-card-home">When nature and body say thank you</h2>
                                <p className="text-card-home">
                                    To think of your and environmental wellness, even in the moment of personal joy, has become an imperative part of nourishment long ago. Groceries we consume on a daily basis, many of them produced in distant parts of the world, through long term consumption impact our body, which is why it is important to dedicate special attention to their quality, that is, the way of production. Besides that, the process of production implies not only creation of the product but also the whole trading chain from the manufacturers to consumers, where many participants do not get their fair share of assets. This can especially be applied on poor countries with often unregulated labor rights and even child exploitation.
                                </p>
                                <Link to="/omcafe-organic-coffee" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        READ MORE
                                        </button>
                                </Link>
                            </div>
                        </Col>
                        <Col lg={6} className="p-0">
                            <img src={OrganicCoffee} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                centered
                size="lg"
                show={show}
                onHide={handleClose}
                keyboard>
                <Modal.Body>
                    <Close className="close-icon-modal-home" onClick={handleClose} />
                    <h4 class="subtitle-modal-card-home">OMCAFÉ NEWSLETTER</h4>
                    <h3 className="title-modal-home">OMCAFÉ Fan Club</h3>
                    <form>
                        <Row>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="First and last name" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="Address" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="City" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="Mobile number" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="email" placeholder="E-mail" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="Date of Birth" />
                            </Col>
                            <Col sm={12}>
                                <button className="button-modal-card-home">
                                    BECOME A MEMBER
                                </button>
                            </Col>
                            <Col sm={12}>
                                <p className="text-form-modal-home">Filling in this form I confirm my data can be used for data base and for promotional purposes of Bom Impeks Ltd.</p>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default HomeEn;