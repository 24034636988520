import React from 'react'

// components
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";


// style
import '../Home/style.css';

// images
import isidora from '../Home/images/isidora.jpg'
import marijana from '../Home/images/marijana.jpg'
import elena from '../Home/images/elena.jpg'
import emina from '../Home/images/emina.jpg'
import iva from '../Home/images/iva.jpg'
import nastja from '../Home/images/nastja.jpg'
import emilija from '../Home/images/emilija.jpg'
import galina from '../Home/images/galina.jpg'
import ines from '../Home/images/ines.jpg'
import lejla from '../Home/images/lejla.jpg'

const ZenskePrice = () => {
    return (
        <Container fluid>
            <Row className="column-reverse-card-home">
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Lejla</h2>
                        <p className="text-card-home">
                            Pročitajte „Ženske priče“ i neka vaše misli otputuju u svijet nježnosti.
                        </p>
                        <Link to="/lejla" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
                <Col lg={6} className="p-0">
                    <img src={lejla} alt="Ženske priče - Lejla - Omcafe BIH" className="image-card-home" />
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={ines} alt="Ženske priče - Ines - Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Ines</h2>
                        <p className="text-card-home">
                            O izgubljenim prijateljstvima i onim koja još traju, pročitajte u našim „Ženskim pričama“.
                        </p>
                        <Link to="/ines" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Galina</h2>
                        <p className="text-card-home">
                            Da možete vratiti vrijeme, šta biste danas uradili drugačije?
                            Napisali smo za vas „Ženske priče“ – privatne istorije svačije duše.
                        </p>
                        <Link to="/galina" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
                <Col lg={6} className="p-0">
                    <img src={galina} alt="Ženske priče - Galina - Omcafe BIH" className="image-card-home" />
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={emilija} alt="Ženske priče - Emilija - Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Emilija</h2>
                        <p className="text-card-home">
                            Upoznajte „Ženske priče“ – intimni pogled na ženu u svakom od nas.
                        </p>
                        <Link to="/emilija" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Nastja</h2>
                        <p className="text-card-home">
                            Vjerujete li u dobru namjeru i iskrene ljude?
                        </p>
                        <Link to="/nastja" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
                <Col lg={6} className="p-0">
                    <img src={nastja} alt="Ženske priče - Nastja - Omcafe BIH" className="image-card-home" />
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={iva} alt="Ženske priče - Iva - Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Iva</h2>
                        <p className="text-card-home">
                            Slažete li se da je djevojaštvo najljepši period života žene?
                        </p>
                        <Link to="/iva" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Emina</h2>
                        <p className="text-card-home">
                            Znate li šta najviše želite?
                        </p>
                        <Link to="/emina" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
                <Col lg={6} className="p-0">
                    <img src={emina} alt="Ženske priče - Emina - Omcafe BIH" className="image-card-home" />
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={elena} alt="Ženske priče - Elena - Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Elena</h2>
                        <p className="text-card-home">
                            Za koju staru stvar ste sentimenalno vezani?
                        </p>
                        <Link to="/elena" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Isidora</h2>
                        <p className="text-card-home">
                            Imate li hrabrosti da danas saoštite sebi nešto važno?
                        </p>
                        <Link to="/isidora" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
                <Col lg={6} className="p-0">
                    <img src={isidora} alt="Ženske priče - Isidora - Omcafe BIH" className="image-card-home" />
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={marijana} alt="Ženske priče - Marijana - Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Ženske priče</h4>
                        <h2 className="title-card-home">Marijana</h2>
                        <p className="text-card-home">
                            „Neustrašivost je moćna riječ koju, obećavam sebi, odsad ispijam sa svakim buđenjem, svježu i vrelu, kao prvu jutarnju kafu…“
                            Kojoj osobi se divite na njenoj neustrašivosti?
                            Pronađite vašu Žensku priču.
                            Omcafe poklon paket “Ženske priče” uskoro će biti dostupan u svim većim prodajnim mjestima
                        </p>
                        <Link to="/marijana" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priču
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>


        </Container>
    )
}

export default ZenskePrice;