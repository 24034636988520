import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import marijana from '../Home/images/marijana.jpg'

const Marijana = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={marijana} alt="Ženske priče - Marijana - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Marijana</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Koliko pokušaja je dovoljno kako bih rekla da sam sve probala? Gdje prestaje tuđe „ne može“, a gdje počinje moje „ne smijem“? Jedan dječak je tužno posmatrao kako avion od papira nadlijeće ogradu balkona i nestaje van domašaja mogućeg. Na igralištu su se sva djeca okretala na prečki, samo sam ja stajala po strani, nemoćna da razriješim dilemu da li ne smijem ili ne mogu. Nikad neizmireni računi sa samom sobom možda nikad neće doći na naplatu ako se dovoljno jako oduprem potrebi da prošle mogućnosti opravdam kao odsustvo smjelosti. Čemu se smješkamo dok gledamo mališana kako jurca za starijom komšijskom djecom? Šta nas zadivljuje u činu gubitnika koji se bori do posljednje sekunde i kada niko ne pomišlja dovesti u pitanje njegovo dostojanstvo? Neustrašivost! Ta veličanstvena riječ od koje smo se sakrili u mišje rupe svakodnevice. Kako baratate pojmom koji u sebi krije nešto <i>strašno</i>?
                            </p>
                            <p className="text-single-news">
                                Ne pada vam na pamet da ga rasipate na osrednjost. To je kvalitet koji podrazumijeva divljenje. A divljenje je opet nešto <i>divovski</i> krasno. Želim za sebe to sve – neustrašivo, divno i krasno! Čitam vas već: <i>odrasti, Marijana, nije dovoljno poželjeti pa da bude moguće</i>. Ali, zaboravili ste jedan detalj. Vaše „ne može“ ne izaziva moje „ne smijem“. Previše sam puta vidjela tu lažnu alhemiju kako bih povjerovala da papirni avion iščezava u nemogućem. Ja sam bila sa druge strane. I znam odgovor – djeca imaju naše simpatije jer su mala neustrašiva bića u vječitom sukobu sa svijetom u kojem sebe neizostavno gledaju kao pobjednike. Iako su se naša sredstva promijenila, zašto da se odreknemo te uzvišene osobine? Neustrašivost je moćna riječ koju, obećavam sebi, odsad ispijam sa svakim buđenjem, svježu i vrelu, kao prvu jutarnju kafu….
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Marijana;