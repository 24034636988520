import React from 'react'

// style
import './style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import organska from '../Home/images/Omcafe-organic-coffee.jpg'

const NewsOrganskaKafaEn = () => {
    return (
        <>

            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={organska} alt="Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">NEW PRODUCT - ORGANIC COFFEE</h4>
                            <h2 className="title-single-news">When nature and body say thank you</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                To think of your and environmental wellness, even in the moment of personal joy, has become an imperative part of nourishment long ago. Groceries we consume on a daily basis, many of them produced in distant parts of the world, through long term consumption impact our body, which is why it is important to dedicate special attention to their quality, that is, the way of production. Besides that, the process of production implies not only creation of the product but also the whole trading chain from the manufacturers to consumers, where many participants do not get their fair share of assets. This can especially be applied on poor countries with often unregulated labor rights and even child exploitation.
                            </p>
                            <p className="text-single-news">
                                For this, OMCAFÉ company, that operates successfully on our market for the past three decades, responded to these modern age challenges in their own way, certifying its two new products with organic production and fair trade labels - Fair Trade that is Fair For Life. In their attempts to give their contribution to socially responsible behavior, trading with manufacturers from poor areas and countries, and to offer its consumers coffee of pure origin and extraordinary nutritive values, OMCAFÉ company brought OMCAFÉ Premium Organic and OMCAFÉ Espresso Organic to the market.
                            </p>
                            <p className="text-single-news">
                                Huge accomplishment is behind the simple name of organic coffee. In other words, this means that not only the plant is grown without the use of agrochemical products, but the entire production-trade chain, from growing and transport to roasting, must be certified as organic. International organization for certification of organic and ecological products - Ecocert, awarded OMCAFÉ with this valuable label for this long and verified journey. In addition, it has a Fair for Life certificate based on Fair Trade, that is on fair business relations, fair labor relations and respect for the environment within and along the whole chain of supply.
                            </p>
                            <p className="text-single-news">
                                Lovers of traditional taste of OMCAFÉ Premium can enjoy its organic version in a perfect blend of arabica and robusta beans from India, Mexico, and Nicaragua. OMCAFÉ Espresso Organic is processed to the lowest level of roasting, and both are produced in 100% organic conditions as a result of 100% fair business.
                            </p>
                            <p className="text-single-news">
                                When you buy organic coffee, you are doing a right thing for your body, and you also support the farmers who are dedicated to the environment and help in promotion of biodiversity.
                            </p>
                            <p className="text-single-news">
                                <span className="italic-news"><span className="bold-news">Improve yourself and your environment, enjoy cleverly. Wish everyone well.</span></span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default NewsOrganskaKafaEn;