import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import elena from '../Home/images/elena.jpg'

const Elena = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={elena} alt="Ženske priče - Elena - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Elena</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Svi imamo neku vremenom nazubljenu stvar koju bismo više voljeli i da nam neko na prag sruči cio vagon novih: otrcanu pamučnu potkošulju sa rastegnutim šavovima, izblijedjele, razgažene cipele naborane poput lica osamdesetogodišnjeg barbe, pokrivljenu viljušku ili okrnjenu šoljicu za kafu. U mom slučaju, to su dvije stvari: potkošulja koju sam konzervirala na sigurno u dubinu ladice sa vešom, kao prvu pomoć u slučaju hitne potrebe za nježnošću koja bi me mogla zadesiti u sitan sat. Druga je stvar šolja za kafu, sa neobičnom funkcijom mjerača odrastanja. Na početak priče nisam ponosna – postala je moja onog dana kada sam iz srdžbe, jer je stariji brat dobio krofnu s marmeladom a ja onu sa štaubom, polupala svoju prekrasnu bijelu šolju sa naslikanim ružama, a baka mi za nauk i opomenu da ne izrastem u srditu djevojčicu dala tu skromnu, ni po čemu zanimljivu, ljubičastu šolju sa bijelim tufnama i krivo salivenom ručkom. Da gledam u nju svakog jutra dok ispijam mlijeko i podsjećam se kako sam uz malo pristojnosti i trpljenja mogla i dalje biti vlasnica ružinih pupoljaka na porcelanu.
                            </p>
                            <p className="text-single-news">
                                Lekcija je učena, jedna po jedna. Za to vrijeme su se u ljubičastoj šolji, rezervisanoj strogo za doručak s bakom, smjenjivali napici. Već krajem osnovne škole bjelinu mlijeka pomutila je koja kapljica crne domaće kafe koju mi je baka sipala krišom od mame, a koja je ukus keksa dizala na sedmo nebo. Zatim je došao dan kada sam važno baki pokazala kako šolju mlijeka jednim potezom iz kesice pretvaram u kafe-vaniliju, sa čijeg vrha pretiče pjena niz tufnaste rubove šolje, a baka bi zatražila da je i njoj napravim onih dana kad od jurnjave za loncima u kuhinji ne bi stigla spremiti svoju. Tada su naši razgovori počeli da se govore tiše i povjerljivije.
                            </p>
                            <p className="text-single-news">
                                Dok je ljubičasta šolja počela mirisati na crnu, domaću kafu kakvu je baka pila, nje više nije bilo među nama; a od naših susreta ostala je krivo salivena ručka kao spomenik svemu – odrastanju, nesavršenosti, učenju. Vidite, stvar sa potkošuljom je jednostavna – ima svoju veličinu koju su tijelo, voda i sunce kreativno deformisali, gdje se vi možete uklopiti ili ne. Ali šoljica, to je već ozbiljniji igrač: njena je veličina prihvatila sve moje uzraste i ukuse, sve hirove i navike koje sam napustila, a prije svega – bila je lekcija kojom sam naučena da postanem bolja osoba. I kažem sebi – nevolje, tu su samo krivo salivene ručke budućih mudrosti.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Elena;