import React, { useState } from 'react'
import './style.css'
import Logo from './images/Logo'
import Close from './images/Close'
import IzgovorZaRazgovor from './images/IzgovorZaRazgovor'

import IconHamburger from './images/IconHamburger'
import Language from './images/Language'

import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const NavigationEn = () => {
    const [isActive, setIsActive] = useState(false);
    return (
        <>
            <div className={`menu ${isActive ? 'menu--active' : ''}`}>
                <Container>
                    <Row className="form-inline">
                        <Col xs={10} className="col-logo-menu">
                            <Logo className="logo-menu" /> <IzgovorZaRazgovor className="text-logo-menu" />
                        </Col>
                        <Col xs={2} className="col-close-menu">
                            <Close className="close-menu" onClick={() => setIsActive(!isActive)} />
                        </Col>
                    </Row>
                    <div className="position-menu">
                        <Row>
                            <Col xs={5} sm={5} md={5} lg={4}>
                                <h4 className="title-menu">Omcafé</h4>
                                <Link to="/" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Home
                                    </p>
                                </Link>
                                <Link to="/about-us" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        About us
                                    </p>
                                </Link>
                                <Link to="/contact-us" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Contact
                                    </p>
                                </Link>
                                <Link to="/news" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        News
                                    </p>
                                </Link>
                                <a href="https://shop.omcafe.net/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Web shop
                                    </p>
                                </a>
                            </Col>
                            <Col xs={7} sm={7} md={7} lg={5}>
                                <h4 className="title-menu">Products</h4>
                                <Link to="/traditional-coffee" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Traditional
                                    </p>
                                </Link>
                                <Link to="/espresso-coffee" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Espresso 
                                    </p>
                                </Link>
                                <Link to="/instant-coffee" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Instant
                                    </p>
                                </Link>
                                <Link to="/espresso-capsules" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Capsules
                                    </p>
                                </Link>
                            </Col>
                            <Col xs={7} sm={7} md={7} lg={3} className="language">
                                <h4 className="title-menu form-inline"> <Language className="icon-language" />Language</h4>
                                <Link to="/" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        English
                                    </p>
                                </Link>
                                <a href="https://www.omcafe.net/" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Srpski
                                    </p>
                                </a>
                            </Col> 
                        </Row>
                    </div>
                </Container>
            </div>
            <div className="navigation">
                <Container>
                    <div className="items-position">
                        <Link to="/" className="reset-link-style">
                            <Logo className="logo" />
                        </Link>
                        <IconHamburger className="icon-hamburger" onClick={() => setIsActive(!isActive)} />
                    </div>
                </Container>
            </div>
        </>
    )
}

export default NavigationEn