import * as React from "react"

function SvgComponent(props) {
	return (
		<svg
			id="prefix__Layer_1"
			x={0}
			y={0}
			viewBox="0 0 142 142"
			xmlSpace="preserve"
			{...props}
		>
			<style>{".prefix__st0{fill:#836c60}"}</style>
			<path
				className="prefix__st0"
				d="M99.5 49.4c-.6-1.6-1.5-3-2.7-4.2s-2.6-2.1-4.2-2.7c-2.2-.8-4.5-1.2-6.8-1.2-3.9-.2-5-.2-14.8-.2s-11 0-14.8.2c-2.3 0-4.6.4-6.8 1.2-3.1 1.2-5.7 3.7-6.9 6.9-.7 2.2-1.2 4.5-1.2 6.8-.2 3.9-.2 5-.2 14.8s0 11 .2 14.8c0 2.3.4 4.6 1.2 6.8.6 1.6 1.5 3 2.7 4.2 1.2 1.2 2.6 2.1 4.2 2.7 2.2.8 4.5 1.2 6.8 1.2 3.9.2 5 .2 14.8.2s11 0 14.8-.2c2.3 0 4.6-.4 6.8-1.2 3.1-1.2 5.7-3.7 6.9-6.9.7-2.2 1.2-4.5 1.2-6.8.2-3.9.2-5 .2-14.8s0-11-.2-14.8c0-2.3-.4-4.6-1.2-6.8zM71 89.7c-10.4 0-18.8-8.3-18.8-18.7S60.6 52.2 71 52.2s18.8 8.4 18.8 18.7c0 10.4-8.4 18.8-18.8 18.8zm19.5-33.8c-2.4 0-4.4-2-4.4-4.4s2-4.4 4.4-4.4 4.4 2 4.4 4.4-2 4.4-4.4 4.4z"
			/>
			<path
				className="prefix__st0"
				d="M71 1C32.3 1 1 32.3 1 71s31.3 70 70 70 70-31.3 70-70S109.7 1 71 1zm36.4 70c0 10 0 11.2-.2 15.1-.1 3.1-.6 6.1-1.7 8.9-1.9 4.9-5.7 8.8-10.6 10.7-2.9 1.1-5.9 1.6-8.9 1.7-3.9.2-5.2.2-15.1.2-9.9 0-11.1 0-15-.2-3.1-.1-6.1-.6-8.9-1.7-4.9-1.9-8.8-5.8-10.7-10.7-1.1-2.9-1.6-5.9-1.7-8.9-.2-3.9-.2-5.2-.2-15.1 0-9.9 0-11.2.2-15.1.1-3.1.6-6.1 1.7-8.9.9-2.5 2.3-4.7 4.2-6.5 1.8-1.8 4.1-3.3 6.5-4.2 2.9-1.1 5.9-1.6 8.9-1.7 3.8-.2 5.1-.2 15-.2s11.2 0 15.1.2c3.1.1 6.1.6 8.9 1.7 2.5.9 4.7 2.3 6.5 4.2 1.8 1.8 3.3 4.1 4.2 6.5 1.1 2.9 1.6 5.9 1.7 8.9.2 3.9.1 5.1.1 15.1z"
			/>
			<circle className="prefix__st0" cx={71} cy={71} r={12.2} />
		</svg>
	)
}

export default SvgComponent
