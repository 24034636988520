import * as React from "react"

function SvgComponent(props) {
  return (
    <svg fill="#fff" viewBox="0 0 341.3 213.3" {...props}>
      <path d="M0 33.4h341.3v22.7H0zM0 157.4h341.3v22.7H0z" />
    </svg>
  )
}

export default SvgComponent
