import React from 'react'
import './style.css'

// components
import { Container, Row, Col } from 'react-bootstrap'
// images
import espresso from './images/espresso-omcafe.jpg'
import Coffee from '../../assets/images/intro/Coffee'
import shadow from '../../assets/images/intro/shadow.jpg'

// products images
import strong from './images/products/strong-espresso-omcafe.png'
import corposo from './images/products/corposo-espresso-omcafe.png'
import firenze from './images/products/firenze-espresso-omcafe.png'
import forte from './images/products/forte-espresso-omcafe.png'
import venezia from './images/products/venezia-espresso-omcafe.png'
import organic from './images/products/organic-espresso-omcafe.png'

const EspressoEn = () => {
    return (
        <>
            <div>
                <img className="intro-image" src={espresso} alt="omcafe" />
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} className="text-center">
                        <p className="text-intro">
                            The impression that espresso leaves on our palate is worthy of its name; it is a fast, highly aromatic, caffeine experience in a small cup. Powerful and tasty, it represents highly concentrated coffee bean extract that stays with your senses for a long time. Each bean hides in it an abundance of flavors, but, the accentuation of the most tasty elements is a secret of roasting procedure necessary to create an ideal espresso. That is why the task of creating new values is a task entrusted only to experts.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Coffee className="intro-image-beans" />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <img className="intro-image-shadow" src={shadow} alt="omcafe" />
                    </Col>
                </Row>


                {/* card organic */}
                <Row>
                    <Col>
                        <div className="card-espresso background-organic-card-espresso padding-left-card-espresso">
                            <Row className="column-reverse-card-espresso">
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-card-espresso">
                                    <div className="align-content-left-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Organic</h2>
                                        <p className="text-card-espresso">A seductive blend of arabica and robusta, certifiably 100% organic in production, packed in protective atmosphere. </p>
                                        <p className="sub-text-card-espresso">WEIGHT: 1000g</p>
                                        <button className="button-card-espresso">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <img className="image-card-espresso" src={organic} alt="omcafe espresso coffee" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {/* card 1 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-strong-card-espresso padding-right-card-espresso">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-card-espresso" src={strong} alt="omcafe espresso coffee" />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <div className="align-content-right-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Strong</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Strong is an intensive, strong coffee recognizable for its full and creamy texture.</p>
                                        <p className="sub-text-card-espresso">WEIGHT: 1000g</p>
                                        <button className="button-card-espresso">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {/* card 2 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-corposo-card-espresso padding-left-card-espresso">
                            <Row className="column-reverse-card-espresso">
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-card-espresso">
                                    <div className="align-content-left-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Corposo</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Corposo is an exotic blend in which bean processing procedure Espresso Roast is especially accentuated.</p>
                                        <p className="sub-text-card-espresso">WEIGHT: 1000g</p>
                                        <button className="button-card-espresso">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <img className="image-card-espresso" src={corposo} alt="omcafe espresso coffee" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


                {/* card 3 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-forte-card-espresso padding-right-card-espresso">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-card-espresso" src={forte} alt="omcafe espresso coffee" />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <div className="align-content-right-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Forte</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Forte is especially full in taste, and its particularly sweet tone of the aroma is brought by the Italian Roast. </p>
                                        <p className="sub-text-card-espresso">WEIGHT: 1000g</p>
                                        <button className="button-card-espresso">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


                {/* card 4 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-venezia-card-espresso padding-left-card-espresso">
                            <Row className="column-reverse-card-espresso">
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-card-espresso">
                                    <div className="align-content-left-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Venezia</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Venezia has a refined flavor, moderate roasting and creamy texture.</p>
                                        <p className="sub-text-card-espresso">WEIGHT: 1000g</p>
                                        <button className="button-card-espresso">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <img className="image-card-espresso" src={venezia} alt="omcafe espresso coffee" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {/* card 5 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-firenze-card-espresso padding-right-card-espresso last-card-espresso">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-card-espresso" src={firenze} alt="omcafe espresso coffee" />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <div className="align-content-right-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Firenze</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Firenze is of milder intensity and contains a high percentage of most quality arabica.</p>
                                        <p className="sub-text-card-espresso">WEIGHT: 1000g</p>
                                        <button className="button-card-espresso">ADD TO SHOPPING CART</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default EspressoEn;