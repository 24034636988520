import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import lejla from '../Home/images/lejla.jpg'

const Lejla = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={lejla} alt="Ženske priče - Lejla - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Lejla</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                „Kad bi znali koliki smo stvarno, davno bi nas zgazili“, rekao mi je u šali trener na prvom času jahanja. Stajali smo pred štalom gladeći bijeli trbuh matorog bijelog lipicanera koji je krotko stajao zagledan u poljanu. Cijelog popodneva je sipila memljiva kiša kao oblakom prevučena sa engleskih nizija. Dobro se sjećam te važne pouke koju sam dobila, stojeći sa Rubinove lijeve strane, kako bi svojim čudno baždarenim vidom mogao da me sagleda u mojoj iskrivljenoj malenkosti, zvučeći kao politički manifest prirode.
                            </p>
                            <p className="text-single-news">
                                Idućih dana proganjala me je ista misao u hiljadu varijacija – kada bi mravi znali koliko su brojniji od nas, udružili bi se i zaposjeli naše ostave sa hranom, ambare, restorane i kuhinje. Kada bi klasje kukuruza znalo koliko je praznih prostranstava na našim trgovima, stadionima i bulevarima, nicalo bi posvuda i svojim moćnim žilama kidalo beton u paramparčad. Kad bi ptice znale koliko je napuštenih srdaca i usamljenih duša, nastanile bi se u nama prije odlaska na jug. Kad bi rijeke znale koliko je bezvrijedan otpad kojim ih zatrpavamo spram njihove divovske bujice, bljunule bi ga na nas iz svojih poslušnih korita.
                            </p>
                            <p className="text-single-news">
                                Bilo je pozno ljeto, vlažno i svježe. Polja su se s predvečerja punila zlatom, a vazduh sladio mirisom pečenih paprika koji se provlačio kroz proreze drvenih kapija. Bosi, sjedili smo na klupi pod dudom i iz stare tepsije prstima kusali mlaku pita s jabukama. Mačak Grišo zaplitao mi se među rubovima haljine, a ja sam sklanjala vreo ibrik s kafom od dječaka koji su preko stola vijali drvenu čigru. Pričala sam sa prirodom u sebi.
                            </p>
                            <p className="text-single-news">
                                Pozno ljeto je veličanstven smiraj jednog silovitog ciklusa života. Priroda se sama kroti spontanim opadanjem, umjetnički, dostojanstveno. A čovjek? Šta će ukrotiti njegovu plitku samovolju? Da li nas to bezazleno neznanje ili beskrajna trpeljivost prirode, i dalje drže u njenom zagrljaju? Pomislila sam na mog matorog Rubina i njegovog džokeja koji ga gladi po zabreklom trbuhu. Svijest o veličini više je nego sama veličina. Koliko malo nam zaista pripada, a na koliko mnogo polažemo pravo. Klonule, pokošene trave na poljima i prsti uronuli u toplo tijesto. Priroda je zaista, u svakom trenutku, divovski veličanstvena.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Lejla;