import React from 'react'

// style
import '../News/style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import iva from '../Home/images/iva.jpg'

const Iva = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={iva} alt="Ženske priče - Iva - Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Ženske priče</h4>
                            <h2 className="title-single-news">Iva</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                <i>Ivo, uradi ovo, Ivo, uradi ono.</i> Vijaju me i dozivaju po prostorijama bakine kuće, ja čučim sklupčana u ormaru sa dugačkim majčinim haljinama koje mi se sa vješalica survavaju pravo na kike, udišem miris nenošenog muslina i prstićem šaram po dezenu putničke torbe ćušnute u mrak još od posljednjeg ljetovanja na Jadranu. Čekam da prođe galama, pa da đipim nazad na ušuškano mjesto kraj prozora u otrcanu žutu jamboliju i ponovo dohvatim majčinu knjigu. Tu, među usamljenim reliktima djevojaštva, maštom popunjavam i nadovezujem parčad jedne privatne istorije ženstvenosti. Šešir sa izblijedjelom pantljikom, stari karmini na hekleraju i prazna pudrijera, zvečke od sasušenih tobolaca maka zabodene u kristalnu vazu iz Pešte.
                            </p>
                            <p className="text-single-news">
                                Oduvijek mi se činilo – uzvišen je i tajnovit svijet žena. Po nepisanom pravilu, djevojačke sobe pune su predmeta koji lijepo mirišu, služe da uljepšaju ili oduševe, poklona koji raspiruju sentimente i nametljivo traže mjesto u uspomenama, cedulja zaturenih među korice knjiga, primljenih krišom, zbog kojih se šapuće dokasno u noć. Da ne pričam tek o niskama perli koje golicavo miluju vrat, torbicama sa dezenom orhideja, haljinama sa visokim strukom, i svim tim gracioznim stvarima koje muškarci ne razumiju ali ih bez razmišljanja raznose i poklanjaju! Tim drangulijama tješe i udvaraju se, traže oproštaj ili iskazuju kakvo svileno osjećanje koje bi im se zapetljalo u riječima. <i>Daće ti, Ivo, mama, kad malo još poodrasteš</i>. Ali ja sam htjela odmah da uskočim u njene cipele, misleći da me baš one propinju na visinu na kojoj se razotkriva ta opojna, ženska tajna, koju sam nanjušila u njenoj djevojačkoj sobi.
                            </p>
                            <p className="text-single-news">
                                Razmišljam zato danas, dok u kutije spremam prerasle, dječje cipelice moje kćerke, koliko je od tada žena u meni izraslo, poraslo i ustupilo mjesto nekoj novoj meni. Ali ipak sad znam, kao što se sunce nepogrešivo rađa na istoku, da je rađanje tog novog života bilo izvor mog konačnog i istinskog smisla. I da sam time ja, ovako nezgrapna i krhka, jedne zore postala takođe nečiji smisao. Možda je baš to tajna koju sam naslućivala onda u toj sobi, kao nešto mistično, neizrecivo, oblo, toplo i tiho, što mi nijedna od žena mog djetinjstva nije mogla razotkriti dok sama tu nisam došla.
                            </p>
                            <p className="text-single-news">
                                Dođe tako vrijeme kad u djevojačkim sobama, kao pravim malim kabinetima rijetkosti, zamirišu neka nova djevojaštva, a odložene niske perli zamiluju nove, mekane vratove, kao stidljivi pogledi mladića koji za susjednim stolom ispijaju kafu s mlijekom. Jer, takav je život – predivan u svojoj neizrecivosti…
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Iva;