import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 142 142"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st0{fill:#836c60}"}</style>
      <path className="prefix__st0" d="M63 83l20.9-12L63 59z" />
      <path
        className="prefix__st0"
        d="M71 1C32.3 1 1 32.3 1 71s31.3 70 70 70 70-31.3 70-70S109.7 1 71 1zm40 70.1s0 13-1.6 19.3c-1 3.4-3.7 6.1-7.1 7-6.2 1.7-31.3 1.7-31.3 1.7s-25 0-31.4-1.7c-3.5-1-6.2-3.7-7.1-7.1-1.6-6.2-1.6-19.3-1.6-19.3s0-13 1.7-19.3c.9-3.4 3.6-6.1 7-7.1C45.9 43 71 43 71 43s25.1 0 31.3 1.7c3.5 1 6.2 3.7 7.1 7.1 1.7 6.2 1.6 19.3 1.6 19.3z"
      />
    </svg>
  )
}

export default SvgComponent
